@import url("https://use.typekit.net/waf0iqj.css");

body {
  margin: 0;
  font-family: sofia-pro, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

code {
  font-family: monospace;
}

html,
body {
  background-color: #212124;
  height: 100%;
}

:root {
  /* COLORS */
  --White: #ffffff;
  --LightGrey: #949496;
  --MiddleGrey: #3e3e41;
  --DimGrey: #252527;
  --DarkerGrey: #2f2f2f;
  --PaleBlack: #1a1a1c;
  --DarkBluish: #28282c;
  --Body-bg: #212124;
  --opacity-0: rgba(0, 0, 0, 0);
  --opacity-b-001: rgba(0, 0, 0, 0.01);
  --opacity-b-0025: rgba(0, 0, 0, 0.025);
  --opacity-b-005: rgba(0, 0, 0, 0.05);
  --opacity-b-0075: rgba(0, 0, 0, 0.075);
  --opacity-b-01: rgba(0, 0, 0, 0.1);
  --opacity-b-02: rgba(0, 0, 0, 0.2);
  --opacity-b-03: rgba(0, 0, 0, 0.3);
  --opacity-b-05: rgba(0, 0, 0, 0.5);
  --opacity-b-07: rgba(0, 0, 0, 0.7);
  --opacity-b-09: rgba(0, 0, 0, 0.9);
  --opacity-w-001: rgba(255, 255, 255, 0.01);
  --opacity-w-0025: rgba(255, 255, 255, 0.025);
  --opacity-w-005: rgba(255, 255, 255, 0.05);
  --opacity-w-0075: rgba(255, 255, 255, 0.075);
  --opacity-w-01: rgba(255, 255, 255, 0.1);
  --opacity-w-02: rgba(255, 255, 255, 0.2);
  --opacity-w-03: rgba(255, 255, 255, 0.3);
  --opacity-w-05: rgba(255, 255, 255, 0.5);
  --opacity-w-07: rgba(255, 255, 255, 0.7);
  --opacity-w-08: rgba(255, 255, 255, 0.8);
  --opacity-w-09: rgba(255, 255, 255, 0.9);
  --Site-background: #262629;
  --DelicBlack: #191919;
  --Black: #000000;
  --Green: #00b092;
  --Light-Green: #28c882;
  --Red: #ff3200;
  --Blue: #0062ff;
  --Rich-blue: #0000fa;
  --Light-blue: #1964ff;
  --Aqua: #02bec4;
  --Yellow: #ffc800;
  --Orange: #ff9900;
  --Purple: #b855e5;
  --Pink: #f064c8;
  --Transparent: rgba(255,255,255,0);
  /* FONT WEIGHTS */
  --Light: 300;
  --Regular: 400;
  --Medium: 500;
  --Semi-Bold: 600;
  --Bold: 700;
  --delic-font: sofia-pro, sans-serif;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

#root {
  min-height: 100%;
}

/* APP LAYOUT */
.App {
  color: #ffffff;
  overflow: hidden;
  min-height: 100%;
}

#main-container {
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.light-mode {
  color: #191919;
  color: var(--DelicBlack);
  background: #ffffff;
  background: var(--White);
}

/* FONTS */
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul,
li,
button,
div {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
}

.semi-bold-text {
  font-weight: 600;
  font-weight: var(--Semi-Bold);
}
.bold-text {
  font-weight: 700;
  font-weight: var(--Bold);
}
.medium-text {
  font-weight: 500;
  font-weight: var(--Medium);
}
.semi-bold-text {
  font-weight: 600;
  font-weight: var(--Semi-Bold);
}
.regular-text {
  font-weight: 400;
  font-weight: var(--Regular);
}
.light-text {
  font-weight: 300;
  font-weight: var(--Light);
}

/* FONT SIZE */
.f-sz-10 {
  font-size: 0.625em !important;
}
.f-sz-11 {
  font-size: 0.688em !important;
}
.f-sz-12 {
  font-size: 0.75em !important;
}
.f-sz-13 {
  font-size: 0.813em !important;
}
.f-sz-14 {
  font-size: 0.875em !important;
}
.f-sz-15 {
  font-size: 0.938em !important;
}
.f-sz-16 {
  font-size: 1em !important;
}
.f-sz-17 {
  font-size: 1.063em !important;
}
.f-sz-18 {
  font-size: 1.125em !important;
}
.f-sz-19 {
  font-size: 1.188em !important;
}
.f-sz-20 {
  font-size: 1.25em !important;
}
.f-sz-21 {
  font-size: 1.313em !important;
}
.f-sz-22 {
  font-size: 1.375em !important;
}
.f-sz-24 {
  font-size: 1.5em !important;
}
.f-sz-32 {
  font-size: 2em !important;
}
.f-sz-40 {
  font-size: 2.5em !important;
}
.f-sz-48 {
  font-size: 3em !important;
}
.f-sz-60 {
  font-size: 3.75em !important;
}
.f-sz-70 {
  font-size: 4.375em !important;
}
.f-sz-80 {
  font-size: 5em !important;
}
.f-sz-56 {
  font-size: 3.5em !important;
}
.f-sz-90 {
  font-size: 5.625em !important;
}

/* LINE HEIGHT */
.f-lh-0-6 {
  line-height: 0.6;
}
.f-lh-0-7 {
  line-height: 0.7;
}
.f-lh-0-8 {
  line-height: 0.8;
}
.f-lh-0-9 {
  line-height: 0.9;
}
.f-lh-1 {
  line-height: 1;
}
.f-lh-1-1 {
  line-height: 1.1;
}
.f-lh-1-2 {
  line-height: 1.2;
}
.f-lh-1-4 {
  line-height: 1.4;
}
.f-lh-1-5 {
  line-height: 1.5;
}
.f-lh-1-6 {
  line-height: 1.6;
}
.f-lh-1-7 {
  line-height: 1.7;
}
.f-lh-1-8 {
  line-height: 1.8;
}
.f-lh-1-9 {
  line-height: 1.9;
}
.f-lh-2 {
  line-height: 2;
}

/* BUTTON BACKGROUND */
.btn-blue {
  background-color: #0062ff;
  background-color: var(--Blue);
}
.btn-rich-blue {
  background-color: #0000fa;
  background-color: var(--Rich-blue);
}
.btn-light-blue {
  background-color: #1964ff;
  background-color: var(--Light-blue);
}
.btn-google-blue {
  background-color: #4285f4;
}
.btn-facebook-blue {
  background-color: #4267b2;
}
.btn-aqua {
  background-color: #02bec4;
  background-color: var(--Aqua);
}
.btn-green {
  background-color: #00b092;
  background-color: var(--Green);
}
.btn-light-green {
  background-color: #28c882;
  background-color: var(--Light-Green);
}
.btn-yellow {
  background-color: #ffc800;
  background-color: var(--Yellow);
}
.btn-orange {
  background-color: #ff9900;
  background-color: var(--Orange);
}
.btn-pink {
  background-color: #f064c8;
  background-color: var(--Pink);
}
.btn-purple {
  background-color: #b855e5;
  background-color: var(--Purple);
}
.btn-red {
  background-color: #ff3200;
  background-color: var(--Red);
}
.btn-white {
  background-color: #ffffff;
  background-color: var(--White);
}
.btn-lightGrey {
  background-color: #949496;
  background-color: var(--LightGrey);
}
.btn-middleGrey {
  background-color: #3e3e41;
  background-color: var(--MiddleGrey);
}
.btn-darkGrey {
  background-color: #2f2f2f;
  background-color: var(--DarkerGrey);
}
.btn-black {
  background-color: #000000;
  background-color: var(--Black);
}
.btn-PaleBlack {
  background-color: #1a1a1c;
  background-color: var(--PaleBlack);
}
.btn-delicBlack {
  background-color: #191919;
  background-color: var(--DelicBlack);
}
.btn-transparent {
  background-color: rgba(255,255,255,0);
  background-color: var(--Transparent);
}
.btn-op-0 {
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--opacity-0);
}

/* BUTTON BACKGROUND OPACITY */
.btn-op-0 {
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--opacity-0);
}
.btn-op-b-001 {
  background-color: rgba(0, 0, 0, 0.01);
  background-color: var(--opacity-b-001);
}
.btn-op-b-0025 {
  background-color: rgba(0, 0, 0, 0.025);
  background-color: var(--opacity-b-0025);
}
.btn-op-b-005 {
  background-color: rgba(0, 0, 0, 0.05);
  background-color: var(--opacity-b-005);
}
.btn-op-b-0075 {
  background-color: rgba(0, 0, 0, 0.075);
  background-color: var(--opacity-b-0075);
}
.btn-op-b-01 {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--opacity-b-01);
}
.btn-op-b-03 {
  background-color: rgba(0, 0, 0, 0.3);
  background-color: var(--opacity-b-03);
}
.btn-op-b-05 {
  background-color: rgba(0, 0, 0, 0.5);
  background-color: var(--opacity-b-05);
}
.btn-op-b-07 {
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--opacity-b-07);
}
.btn-op-b-09 {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: var(--opacity-b-09);
}
.btn-op-w-001 {
  background-color: rgba(255, 255, 255, 0.01);
  background-color: var(--opacity-w-001);
}
.btn-op-w-0025 {
  background-color: rgba(255, 255, 255, 0.025);
  background-color: var(--opacity-w-0025);
}
.btn-op-w-005 {
  background-color: rgba(255, 255, 255, 0.05);
  background-color: var(--opacity-w-005);
}
.btn-op-w-0075 {
  background-color: rgba(255, 255, 255, 0.075);
  background-color: var(--opacity-w-0075);
}
.btn-op-w-01 {
  background-color: rgba(255, 255, 255, 0.1);
  background-color: var(--opacity-w-01);
}
.btn-op-w-03 {
  background-color: rgba(255, 255, 255, 0.3);
  background-color: var(--opacity-w-03);
}
.btn-op-w-05 {
  background-color: rgba(255, 255, 255, 0.5);
  background-color: var(--opacity-w-05);
}
.btn-op-w-07 {
  background-color: rgba(255, 255, 255, 0.7);
  background-color: var(--opacity-w-07);
}
.btn-op-w-09 {
  background-color: rgba(255, 255, 255, 0.9);
  background-color: var(--opacity-w-09);
}

/* TODO check me in delic app and then remove me */
/* BACKGROUND-COLOR*/
.background-color-white {
  background-color: #ffffff;
  background-color: var(--White);
}

/* BUTTON COLOR */
.btn-color-blue,
.color-blue {
  color: #0062ff;
  color: var(--Blue);
}
.btn-color-rich-blue,
.color-rich-blue {
  color: #0000fa;
  color: var(--Rich-blue);
}
.btn-color-light-blue,
.color-light-blue {
  color: #1964ff;
  color: var(--Light-blue);
}
.btn-color-google-blue,
.color-google-blue {
  color: #4285f4;
}
.btn-color-facebook-blue,
.color-facebook-blue {
  color: #4267b2;
}
.btn-color-aqua,
.color-aqua {
  color: #02bec4;
  color: var(--Aqua);
}
.btn-color-green,
.color-green {
  color: #00b092;
  color: var(--Green);
}
.btn-color-yellow,
.color-yellow {
  color: #ffc800;
  color: var(--Yellow);
}
.btn-color-pink,
.color-pink {
  color: #f064c8;
  color: var(--Pink);
}
.btn-color-red,
.color-red {
  color: #ff3200;
  color: var(--Red);
}
.btn-color-black,
.color-black {
  color: #000000;
  color: var(--Black);
}
.btn-color-white,
.color-white {
  color: #ffffff;
  color: var(--White);
}
.btn-color-lightGrey,
.color-lightGrey {
  color: #949496;
  color: var(--LightGrey);
}
.btn-color-middleGrey,
.color-middleGrey {
  color: #3e3e41;
  color: var(--MiddleGrey);
}
.btn-color-darkGrey,
.color-darkGrey {
  color: #2f2f2f;
  color: var(--DarkerGrey);
}
.btn-color-transparent,
.color-transparent {
  color: rgba(255,255,255,0);
  color: var(--Transparent);
}

/* OPACITY COLOR  */
.btn-color-op-0,
.color-op-0 {
  color: rgba(0, 0, 0, 0);
  color: var(--opacity-0);
}
.btn-color-op-b-0025,
.color-op-b-0025 {
  color: rgba(0, 0, 0, 0.025);
  color: var(--opacity-b-0025);
}
.btn-color-op-b-005,
.color-op-b-005 {
  color: rgba(0, 0, 0, 0.05);
  color: var(--opacity-b-005);
}
.btn-color-op-b-0075,
.color-op-b-0075 {
  color: rgba(0, 0, 0, 0.075);
  color: var(--opacity-b-0075);
}
.btn-color-op-b-01,
.color-op-b-01 {
  color: rgba(0, 0, 0, 0.1);
  color: var(--opacity-b-01);
}
.btn-color-op-b-03,
.color-op-b-03 {
  color: rgba(0, 0, 0, 0.3);
  color: var(--opacity-b-03);
}
.btn-color-op-b-05,
.color-op-b-05 {
  color: rgba(0, 0, 0, 0.5);
  color: var(--opacity-b-05);
}
.btn-color-op-b-07,
.color-op-b-07 {
  color: rgba(0, 0, 0, 0.7);
  color: var(--opacity-b-07);
}
.btn-color-op-b-09,
.color-op-b-09 {
  color: rgba(0, 0, 0, 0.9);
  color: var(--opacity-b-09);
}
.btn-color-op-w-0025,
.color-op-w-0025 {
  color: rgba(255, 255, 255, 0.025);
  color: var(--opacity-w-0025);
}
.btn-color-op-w-005,
.color-op-w-005 {
  color: rgba(255, 255, 255, 0.05);
  color: var(--opacity-w-005);
}
.btn-color-op-w-0075,
.color-op-w-0075 {
  color: rgba(255, 255, 255, 0.075);
  color: var(--opacity-w-0075);
}
.btn-color-op-w-01,
.color-op-w-01 {
  color: rgba(255, 255, 255, 0.1);
  color: var(--opacity-w-01);
}
.btn-color-op-w-03,
.color-op-w-03 {
  color: rgba(255, 255, 255, 0.3);
  color: var(--opacity-w-03);
}
.btn-color-op-w-05,
.color-op-w-05 {
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
}
.btn-color-op-w-07,
.color-op-w-07 {
  color: rgba(255, 255, 255, 0.7);
  color: var(--opacity-w-07);
}
.btn-color-op-w-09,
.color-op-w-09 {
  color: rgba(255, 255, 255, 0.9);
  color: var(--opacity-w-09);
}

/* BUTTON BORDER */
.btn-border-blue {
  border: 1px solid #0062ff;
  border: 1px solid var(--Blue);
}
.btn-border-rich-blue {
  border: 1px solid #0000fa;
  border: 1px solid var(--Rich-blue);
}
.btn-border-light-blue {
  border: 1px solid #1964ff;
  border: 1px solid var(--Light-blue);
}
.btn-border-google-blue {
  border: 2px solid #4285f4;
}
.btn-border-facebook-blue {
  border: 2px solid #4267b2;
}
.btn-border-aqua {
  border: 1px solid #02bec4;
  border: 1px solid var(--Aqua);
}
.btn-border-green {
  border: 1px solid #00b092;
  border: 1px solid var(--Green);
}
.btn-border-yellow {
  border: 1px solid #ffc800;
  border: 1px solid var(--Yellow);
}
.btn-border-orange {
  border: 1px solid #ff9900;
  border: 1px solid var(--Orange);
}
.btn-border-pink {
  border: 1px solid #f064c8;
  border: 1px solid var(--Pink);
}
.btn-border-red {
  border: 1px solid #ff3200;
  border: 1px solid var(--Red);
}
.btn-border-black {
  border: 1px solid #000000;
  border: 1px solid var(--Black);
}
.btn-border-white {
  border: 1px solid #ffffff;
  border: 1px solid var(--White);
}
.btn-border-lightGrey {
  border: 1px solid #949496;
  border: 1px solid var(--LightGrey);
}
.btn-border-middleGrey {
  border: 1px solid #3e3e41;
  border: 1px solid var(--MiddleGrey);
}
.btn-border-darkGrey {
  border: 1px solid #2f2f2f;
  border: 1px solid var(--DarkerGrey);
}
.btn-border-no {
  border: 1px solid rgba(255,255,255,0);
  border: 1px solid var(--Transparent);
}
.btn-border-none {
  border: none;
}

/* BUTTON BORDER TRANSPARENCY*/
.btn-border-op-b-005 {
  border: 1px solid rgba(0, 0, 0, 0.05);
  border: 1px solid var(--opacity-b-005);
}
.btn-border-op-b-0075 {
  border: 1px solid rgba(0, 0, 0, 0.075);
  border: 1px solid var(--opacity-b-0075);
}
.btn-border-op-b-01 {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border: 1px solid var(--opacity-b-01);
}
.btn-border-op-b-03 {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border: 1px solid var(--opacity-b-03);
}
.btn-border-op-b-05 {
  border: 1px solid rgba(0, 0, 0, 0.5);
  border: 1px solid var(--opacity-b-05);
}
.btn-border-op-b-07 {
  border: 1px solid rgba(0, 0, 0, 0.7);
  border: 1px solid var(--opacity-b-07);
}
.btn-border-op-b-09 {
  border: 1px solid rgba(0, 0, 0, 0.9);
  border: 1px solid var(--opacity-b-09);
}
.btn-border-op-w-005 {
  border: 1px solid rgba(255, 255, 255, 0.05);
  border: 1px solid var(--opacity-w-005);
}
.btn-border-op-w-0075 {
  border: 1px solid rgba(255, 255, 255, 0.075);
  border: 1px solid var(--opacity-w-0075);
}
.btn-border-op-w-01 {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border: 1px solid var(--opacity-w-01);
}
.btn-border-op-w-03 {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border: 1px solid var(--opacity-w-03);
}
.btn-border-op-w-05 {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border: 1px solid var(--opacity-w-05);
}
.btn-border-op-w-07 {
  border: 1px solid rgba(255, 255, 255, 0.7);
  border: 1px solid var(--opacity-w-07);
}
.btn-border-op-w-09 {
  border: 1px solid rgba(255, 255, 255, 0.9);
  border: 1px solid var(--opacity-w-09);
}

/* TODO check me in delic app and then remove me */
.btn-small-icon {
  width: 24px;
  height: 24px;
  padding: 0;
}
.btn-medium-icon {
  width: 32px;
  height: 32px;
  padding: 0;
}
.btn-large-icon {
  width: 40px;
  height: 40px;
  padding: 0;
}
.btn-large2x-icon {
  width: 48px;
  height: 48px;
  padding: 0;
}
.btn-extralg-icon {
  width: 72px;
  height: 72px;
  padding: 0;
}

/* BUTTON ONLY ICON */
.btn-24-img {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
}
.btn-28-img {
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 50%;
}
.btn-32-img {
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 50%;
}
.btn-36-img {
  width: 36px;
  height: 36px;
  padding: 0;
  border-radius: 50%;
}
.btn-40-img {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
}
.btn-48-img {
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;
}
.btn-56-img {
  width: 54px;
  height: 54px;
  padding: 0;
  border-radius: 50%;
}
.btn-62-img {
  width: 62px;
  height: 62px;
  padding: 0;
  border-radius: 50%;
}
.btn-70-img {
  width: 70px;
  height: 70px;
  padding: 0;
  border-radius: 50%;
}
.btn-80-img {
  width: 80px;
  height: 80px;
  padding: 0;
  border-radius: 50%;
}

.border-1-red {
  border: 1px solid red;
}
.border-1-lightGrey {
  border: 1px solid #949496;
  border: 1px solid var(--LightGrey);
}

.Empty-Gap {
  height: 20px;
}

/* COLOR */
.color-blue {
  color: #0062ff;
  color: var(--Blue);
}
.color-rich-blue {
  color: #0000fa;
  color: var(--Rich-blue);
}
.color-light-blue {
  color: #1964ff;
  color: var(--Light-blue);
}
.color-google-blue {
  color: #4285f4;
}
.color-facebook-blue {
  color: #4267b2;
}
.color-aqua {
  color: #02bec4;
  color: var(--Aqua);
}
.color-light-green {
  color: #28c882;
  color: var(--Light-Green);
}
.color-green {
  color: #00b092;
  color: var(--Green);
}
.color-yellow {
  color: #ffc800;
  color: var(--Yellow);
}
.color-orange {
  color: #ff9900;
  color: var(--Orange);
}
.color-pink {
  color: #f064c8;
  color: var(--Pink);
}
.color-purple {
  color: #b855e5;
  color: var(--Purple);
}
.color-red {
  color: #ff3200;
  color: var(--Red);
}
.color-black {
  color: #000000;
  color: var(--Black);
}
.color-white {
  color: #ffffff;
  color: var(--White);
}
.color-lightGrey {
  color: #949496;
  color: var(--LightGrey);
}
.color-middleGrey {
  color: #3e3e41;
  color: var(--MiddleGrey);
}
.color-darkGrey {
  color: #2f2f2f;
  color: var(--DarkerGrey);
}
.color-delicBlack {
  color: #191919;
  color: var(--DelicBlack);
}
.color-transparent {
  color: rgba(255,255,255,0);
  color: var(--Transparent);
}

/*  OPACITY */
.op-0 {
  opacity: 0;
}
.op-005 {
  opacity: 0.05;
}
.op-0075 {
  opacity: 0.075;
}
.op-01 {
  opacity: 0.1;
}
.op-02 {
  opacity: 0.2;
}
.op-03 {
  opacity: 0.3;
}
.op-05 {
  opacity: 0.5;
}
.op-07 {
  opacity: 0.7;
}
.op-09 {
  opacity: 0.9;
}
.op-1 {
  opacity: 1;
}

/* BUTTONS === LINKS */
button {
  transition: 0.15s ease-in-out;
  cursor: pointer;
}

button:disabled {
  cursor: auto;
  cursor: not-allowed;
}

.transparent-button {
  background: transparent;
  border: 0px;
  color: #0062ff;
  color: var(--Blue);
  font-weight: 900;
}

.disabled-icon {
  opacity: 0.2;
  cursor: not-allowed;
}

a {
  text-decoration: none !important;
  background-color: transparent;
}
.cursor-pointer {
  cursor: pointer;
}

/* FONT AWESOME TWEAKS */
.task-icon-btn-text-label {
  color: #949496;
  color: var(--LightGrey);
  margin-left: 2px;
  font-size: 1em;
  font-weight: 500;
  vertical-align: super;
}

.task-btn::after {
  vertical-align: 0;
}

.task-btn:hover {
  background: #949496;
  background: var(--LightGrey);
  color: #2f2f2f;
  color: var(--DarkerGrey);
}

.editable:hover {
  background: #949496;
  background: var(--LightGrey);
  color: #2f2f2f;
  color: var(--DarkerGrey);
}

.task-calendar-btn-wrapper {
  margin: 0 12px;
}

.task-calendar-btn-wrapper-no-text {
  margin: 0 8px 0 12px;
  width: 22px;
}

.task-calendar-btn-wrapper-small-icon {
  margin: auto;
  height: 14px;
  align-items: center;
}

.task-calendar-btn-text {
  font-size: 12px;
  padding: 0 5px;
}

.user-box-remove {
  border-radius: 0.75em;
}
.profile-image-large {
  width: 80px;
  border-radius: 50%;
  min-width: 80px;
  height: 80px;
}

/* HOVER BG COLORS */
.red-ho-bg:hover {
  background: #ff3200;
  background: var(--Red);
}
.green-ho-bg:hover {
  background: #00b092;
  background: var(--Green);
}
.orange-ho-bg:hover {
  background: #ff9900;
  background: var(--Orange);
}
.white-ho-bg:hover {
  background: #ffffff;
  background: var(--White);
}
.blue-ho-bg:hover {
  background: #0062ff;
  background: var(--Blue);
}
.black-ho-bg:hover {
  background: #191919;
  background: var(--DelicBlack);
}
.light-blue-ho-bg:hover {
  background: #1964ff;
  background: var(--Light-blue);
}
.middle-grey-ho-bg:hover {
  background: #3e3e41;
  background: var(--MiddleGrey);
}
.transparent-ho-bg:hover {
  background: rgba(255,255,255,0);
  background: var(--Transparent);
}
.light-grey-ho-bg:hover {
  background: #949496;
  background: var(--LightGrey);
}
.light-ho-bg:hover {
  background: rgba(255, 255, 255, 0.08);
}

.red-ho-color:hover {
  color: #ff3200;
  color: var(--Red);
}
.green-ho-color:hover {
  color: #00b092;
  color: var(--Green);
}
.orange-ho-color:hover {
  color: #ff9900;
  color: var(--Orange);
}
.white-ho-color:hover {
  color: #ffffff;
  color: var(--White);
}
.blue-ho-color:hover {
  color: #0062ff;
  color: var(--Blue);
}
.dark-ho-color:hover {
  color: #2f2f2f;
  color: var(--DarkerGrey);
}

/* HOVER TRANSPARENCIES */
.dark-grey-ho-bg:hover {
  background: #2f2f2f;
  background: var(--DarkerGrey);
}
.dark-005-ho-bg:hover {
  background: rgba(0, 0, 0, 0.05);
  background: var(--opacity-b-005);
}
.dark-0075-ho-bg:hover {
  background: rgba(0, 0, 0, 0.075);
  background: var(--opacity-b-0075);
}
.dark-01-ho-bg:hover {
  background: rgba(0, 0, 0, 0.1);
  background: var(--opacity-b-01);
}
.dark-02-ho-bg:hover {
  background: rgba(0, 0, 0, 0.2);
  background: var(--opacity-b-02);
}
.dark-03-ho-bg:hover {
  background: rgba(0, 0, 0, 0.3);
  background: var(--opacity-b-03);
}
.dark-05-ho-bg:hover {
  background: rgba(0, 0, 0, 0.5);
  background: var(--opacity-b-05);
}
.dark-07-ho-bg:hover {
  background: rgba(0, 0, 0, 0.7);
  background: var(--opacity-b-07);
}
.dark-09-ho-bg:hover {
  background: rgba(0, 0, 0, 0.9);
  background: var(--opacity-b-09);
}
.light-005-ho-bg:hover {
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
}
.light-0075-ho-bg:hover {
  background: rgba(255, 255, 255, 0.075);
  background: var(--opacity-w-0075);
}
.light-01-ho-bg:hover {
  background: rgba(255, 255, 255, 0.1);
  background: var(--opacity-w-01);
}
.light-02-ho-bg:hover {
  background: rgba(255, 255, 255, 0.2);
  background: var(--opacity-w-02);
}
.light-03-ho-bg:hover {
  background: rgba(255, 255, 255, 0.3);
  background: var(--opacity-w-03);
}
.light-05-ho-bg:hover {
  background: rgba(255, 255, 255, 0.5);
  background: var(--opacity-w-05);
}
.light-07-ho-bg:hover {
  background: rgba(255, 255, 255, 0.7);
  background: var(--opacity-w-07);
}
.light-08-ho-bg:hover {
  background: rgba(255, 255, 255, 0.8);
  background: var(--opacity-w-08);
}
.light-09-ho-bg:hover {
  background: rgba(255, 255, 255, 0.9);
  background: var(--opacity-w-09);
}
/* HOVER OPACITY */
.op-0-ho:hover {
  opacity: 0;
}
.op-005-ho:hover {
  opacity: 0.05;
}
.op-0075-ho:hover {
  opacity: 0.075;
}
.op-01-ho:hover {
  opacity: 0.1;
}
.op-02-ho:hover {
  opacity: 0.2;
}
.op-03-ho:hover {
  opacity: 0.3;
}
.op-05-ho:hover {
  opacity: 0.5;
}
.op-07-ho:hover {
  opacity: 0.7;
}
.op-09-ho:hover {
  opacity: 0.9;
}
.op-1-ho:hover {
  opacity: 1;
}
/* HOVER COLOR */
.red-ho-color:hover {
  color: #ff3200;
  color: var(--Red);
}
.green-ho-color:hover {
  color: #00b092;
  color: var(--Green);
}
.orange-ho-color:hover {
  color: #ff9900;
  color: var(--Orange);
}
.white-ho-color:hover {
  color: #ffffff;
  color: var(--White);
}
.blue-ho-color:hover {
  color: #0062ff;
  color: var(--Blue);
}
.light-blue-ho-color:hover {
  color: #1964ff;
  color: var(--Light-blue);
}
.light-ho:hover {
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
}
.dark-ho:hover {
  -webkit-filter: brightness(0.8);
          filter: brightness(0.8);
}

/* BORDER */
.border-bottom-w {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid var(--opacity-w-01);
}
.border-bottom {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-bottom: 1px solid var(--opacity-w-01) !important;
}
.border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top: 1px solid var(--opacity-w-01) !important;
}
.border-bottom-dark {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-bottom: 1px solid var(--opacity-b-03) !important;
}
.border-top-dark {
  border-top: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-top: 1px solid var(--opacity-b-03) !important;
}

.hidden {
  visibility: hidden;
}
.m-h-100 {
  min-height: 100vh;
}

/* GENERIC MAX HEIGHT */
.max-height-150 {
  max-height: 150px !important;
}

/* ICON COLOR */
.btn-color-icon-black {
  fill: #000000;
  fill: var(--Black);
}

/* BUTTON SHAPE */
.btn-round {
  border-radius: 25px;
}
.btn-round-profile {
  border-radius: 50%;
}
.btn-squared {
  border-radius: 4px;
}

/* BUTTON ONLY ICON */
.btn-small-icon {
  width: 24px;
  height: 24px;
  padding: 0;
}
.btn-medium-icon {
  width: 32px;
  height: 32px;
  padding: 0;
}

/* BUTTON SIZE */
.btn-small {
  height: 20px;
  padding: 0 0.1875em;
  min-width: 20px;
}
.btn-smallx1 {
  height: 24px;
  padding: 0 0.1875em;
  min-width: 24px;
}
.btn-smallx2 {
  letter-spacing: 0.02em;
  height: 32px;
  min-width: 32px;
}
.btn-medium {
  letter-spacing: 0.02em;
  height: 40px;
  min-width: 40px;
}
.btn-large {
  padding: 0 0.75em;
  height: 50px;
  min-width: 50px;
}

.btn-large-livestream {
  font-size: 12px !important;
}
button.dropdown-task {
  font-size: 0.785em;
  padding: 0 0.7em;
  line-height: 1.4em;
}
.dropdown-toggle::after {
  margin-top: 0.6em;
}
button svg {
  pointer-events: none;
}

/* BUTTON WITH TEXT AND ICON */
.btn-small.btn-add-icon {
  background-position: 97% center;
  background-repeat: no-repeat;
}
.btn-medium.btn-add-icon {
  background-position: 90% center;
  background-repeat: no-repeat;
}
.btn-small.btn-add-icon {
  padding: 4px 30px 4px 12px;
}
.btn-small.btn-icon-none {
  padding: 0 12px;
}
.btn-large.btn-add-icon {
  padding-right: 0;
}
.btn-large.btn-icon-none {
  padding: 5px 20px;
}

.social-login-button {
  border-radius: 4px;
  margin-bottom: 10px;
  text-align: center;
  display: block;
  padding: 10px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.social-login-button-icon {
  margin: 0 22px;
  font-size: 24px !important;
}
.social-login-button-message {
  padding-left: 20px;
}

/* This class is used to center the play icon within the circle - do not remove */
.play-btn svg {
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}

.dropdown-task.dropdown:hover button,
.calendar-btn:hover {
  background: #ffffff;
  background: var(--White);
  color: #2f2f2f;
  color: var(--DarkerGrey);
}
a.dropdown-item {
  color: #ffffff;
  color: var(--White);
  font-size: 0.875em;
  padding: 0.875em;
}

/* SPECIFIC BUTTONS */

.edit-task-group-title {
  padding: 0.6em 2em;
  line-height: 1em;
  height: auto;
}
.edit-task {
  padding: 0 0.75em;
  height: 35px;
  min-width: 35px;
}

.task-remove-message {
  font-size: 0.875em;
  margin: 0;
  font-weight: 500;
  font-weight: var(--Medium);
  color: #949496;
  color: var(--LightGrey);
}

.live-member-btn {
  margin-top: -5px !important;
  margin-left: 5px;
}
.eye-dot {
  position: relative;
}
.eye-dot:after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  border-radius: 20px;
  top: 0px;
  right: 0px;
  border: 2px solid #2f2f2f;
  border: 2px solid var(--DarkerGrey);
}
.eye-dot.dot-on::after {
  background: #0062ff;
  background: var(--Blue);
}
.eye-dot.dot-off::after {
  background: #ff9900;
  background: var(--Orange);
}
.delic-form .rbt-input-hint input {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--opacity-w-05) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1.14em !important;
  font-weight: 500 !important;
  line-height: 1.78 !important;
  top: -0.0015rem !important;
}
.rbt-input-hint {
  top: -0.0015rem !important;
}
.acorn-btn:active {
  opacity: 0.7;
}

/* =======================================
   NAV PILLS
========================================== */
.delic-nav-pills.nav-pills .nav-link.active,
.delic-nav-pills.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #0062ff;
  background-color: var(--Blue);
  border: 2px solid #0062ff;
  border: 2px solid var(--Blue);
}
.delic-nav-pills.nav-pills .nav-link {
  background: transparent;
  color: rgba(255, 255, 255, 0.3);
  color: var(--opacity-w-03);
  border: 2px solid rgba(255, 255, 255, 0.3);
  border: 2px solid var(--opacity-w-03);
}

/* =======================================
   DELIC SWITCH
========================================== */

.delic-form .custom-control-label::before {
  background-color: rgba(255, 255, 255, 0.5);
  background-color: var(--opacity-w-05);
  border: none;
}
.delic-form .custom-switch .custom-control-label {
  cursor: pointer;
}
.delic-form .custom-switch .custom-control-label::after {
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--opacity-b-07);
}
.delic-form .custom-control-input:checked ~ .custom-control-label::before {
  border: none;
  background-color: #00b092;
  background-color: var(--Green);
}
.delic-form
  .custom-switch
  .custom-control-input:checked
  ~ .custom-control-label::after {
  background-color: rgba(0, 0, 0, 0.7);
  background-color: var(--opacity-b-07);
}

/* HOVER BUTTONS */
button.toWhite-ho:hover {
  background: #ffffff;
  background: var(--White);
  color: #2f2f2f;
  color: var(--DarkerGrey);
  border: 1px solid #ffffff;
  border: 1px solid var(--White);
}

/* ADD USER ON TASK PANEL */
.add-member-btn {
  padding: 0 !important;
  height: 26px;
  width: 26px;
  background: #000000;
  background: var(--Black);
  margin-left: -8px;
}

.add-member-btn div {
  pointer-events: none;
}

.add-member-btn img {
  width: 24px;
}

.add-member-btn:hover {
  background: #0062ff;
  background: var(--Blue);
  border: 1px solid #0062ff;
  border: 1px solid var(--Blue);
}

.individual-member-btn {
  padding: 0;
  overflow: hidden;
}

.member-btn-reference {
  width: 30px;
  height: 30px;
}

.squeeze-btn {
  margin-left: -8px;
  transition: 0.1s ease-in-out;
}

[dir="ltr"] .safari-message ul{
  padding-left: 1.4em;
}

[dir="rtl"] .safari-message ul{
  padding-right: 1.4em;
}
.safari-message {
  display: none;
}

@media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
  .safari-message {
    display: flex;
  }
}
@media not all and (min-resolution: 0.001dpcm) {
  .safari-message {
    display: flex;
  }
}

.crossed-out {
  text-decoration: line-through;
  color: #949496 !important;
  color: var(--LightGrey) !important;
}

.react-datepicker__close-icon {
  padding: 0 2px 0 0 !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: #949496 !important;
  color: var(--LightGrey) !important;
  height: 30px !important;
  width: 30px !important;
  font-size: 26px !important;
  display: flex !important;
  justify-content: center;
  margin: auto;
  padding: 0 !important;
}

/*LISTS */
li.task-list-li {
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--opacity-0);
  padding: 0;
  margin: 0.5em 0;
}

.task-item-title {
  font-size: 0.875em;
  margin: 0;
  font-weight: 500;
  font-weight: var(--Medium);
  color: #ffffff;
  color: var(--White);
}

.task-list-title-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-list-title-item:after {
  background: rgb(62, 62, 65);
  background: linear-gradient(
    90deg,
    rgba(62, 62, 65, 0.4023984593837535) 0%,
    rgba(62, 62, 65, 1) 70%,
    rgba(62, 62, 65, 1) 100%
  );
  position: absolute;
  width: 21px;
  height: 35px;
  content: "";
  right: 40px;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.455em;
  vertical-align: 0.1em;
  content: "";
  border-top: 0.45em solid;
  border-right: 0.45em solid transparent;
  border-bottom: 0;
  border-left: 0.45em solid transparent;
}

.rearrange-box {
  width: 40px;
}
.add-member-separator.active {
  height: 75px;
}
.add-member-separator {
  height: 16px;
  transition: 0.3s ease-in-out;
}
p.add-member-not-found-option {
  margin: 0 0 0.3em 0;
}

p.add-member-not-found-option-alert {
  color: #ffffff;
  color: var(--White);
  margin: 0 0 0.3em 0;
}

.dropdown-menu {
  padding: 0;
  margin: 0.125rem 0 0;
  color: var(--white);
  background-color: #303030;
  border-radius: 0.5rem;
  overflow: hidden;
}

.dropdown-select-nested {
  background-color: lightgray;
  padding: 0.25rem 0.25rem 0.25rem 1.5rem;
  cursor: pointer;
}

.dropdown-select-nested:hover {
  background-color: #bcbcbc;
}

.rearrange-box img {
  width: 30px;
}

.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-300 {
  width: 300px;
}
.truncate-180 {
  width: 180px;
}
.truncate-90 {
  width: 90%;
}

/* ROUND TICK */
.round {
  width: 42px;
  height: 35px;
}

.checkbox-round {
  width: 16px;
  height: 16px;
  background-color: #3e3e41;
  background-color: var(--MiddleGrey);
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: white;
  box-shadow: inset 0px 0px 0px 2px #3e3e41;
}

.line-separator {
  background-color: #3f3f42;
  height: 3px;
  border-radius: 5px;
  margin: 50px 0;
}

.line-separator-references {
  display: block;
  height: 2px;
  background: #3e3e41;
  width: 100%;
}

.loading-centered {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-40 {
  height: 40% !important;
}

/* ========================
   MIDDLE PANEL CONTAINER
=========================== */

.project-title-header {
  font-size: 1.2em;
  font-weight: 700;
  font-weight: var(--Bold);
  color: #949496;
  color: var(--LightGrey);
  margin: 0;
}
.inspiration-chat-box {
  padding: 1.25rem 0;
}
.inspiration-panel-gap {
  height: 40px;
}
.middle-panel-members {
  height: 80px;
}
.inspiration-panel-header {
  height: 100px;
}

button.inspiration-filter {
  border-radius: 25px;
  background-color: transparent !important;
  font-size: 0.875em !important;
  color: white;
  border: 2px solid var(--blue);
  margin-left: 5%;
  padding: 0 20px;
  line-height: 1.6em;
  height: auto;
}

button.inspiration-filter.selected {
  background-color: var(--blue) !important;
}

/* ========================
   FULLWIDTH PANEL CONTAINER
=========================== */
.header-fullwidth-panel {
  z-index: 100;
  padding: 74px 0 10px;
  transition: 0.15s ease-in-out;
}

.background-black {
  background: #212124;
}

.background-white {
  background: white;
}

/* VIDEO FRAME */
.container-reference {
  margin: 5px 0px;
}

.react-player__preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reference-wrap {
  border-radius: 10px;
  width: 100% !important;
  position: relative;
  padding-bottom: 56.25%;
  height: auto !important;
  overflow: hidden;
}
.reference-image-wrap {
  background-color: #191919;
  background-color: var(--DelicBlack);
  padding-bottom: 0 !important;
}
.reference-image {
  max-width: 100%;
  max-height: 300px;
  margin: auto;
  display: flex;
}
.text-reference {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.3);
  background: var(--opacity-b-03);
  border-radius: 0.5rem;
}
.text-reference a {
  word-break: break-all;
}
.fb-reference-wrap {
  border-radius: 10px;
  overflow: hidden;
  width: 100% !important;
  height: auto !important;
}
.mixer-reference {
  border: 0px;
  overflow: hidden;
  width: 100% !important;
  max-height: 300px;
}

.bandcamp-reference {
  border: 0px none;
  height: 118px;
  margin: -1px;
  width: 101% !important;
  max-height: 300px;
}

.reference-comment p {
  margin-bottom: 0;
  max-width: 30rem;
  font-size: 0.875em;
  line-height: 1.25em;
}

.video-comment-time {
  font-size: 0.875em;
  width: 50px;
}

.reference-comment-time {
  font-size: 0.813em;
  color: #949496;
  color: var(--LightGrey);
  align-items: center;
  display: flex;
  letter-spacing: 0.02em;
  line-height: 1;
  min-height: 2rem;
}

.reference-comment-message {
  font-size: 0.875em;
  margin: 0 10% 0 0;
}

.reference-top-message {
  margin: 20px 0 0;
}

.inspirational-chat-message.row {
  margin: 20px 0;
}

.add-message-tool {
  margin: 0 0 0.5rem;
}

.add-comment-button {
  display: flex;
  justify-content: flex-end;
}

.add-comment-form {
  font-size: 0.875em;
}

.chat-message {
  max-width: 20rem;
  font-size: 0.875em;
  padding: 8px 10px;
  border-radius: 8px;
}

.light-chat-message {
  background-color: #ffffff;
  color: #29282c;
  margin: 0 10% 0 0;
}

.dark-chat-message {
  background-color: #007bff;
  color: white;
  margin: 0px 0px 0px 10%;
}

.chat-message-face {
  justify-content: flex-end;
  display: flex;
  width: 50px;
}

.links-message-face {
  width: 45px;
}

.chat-message-face.right {
  justify-content: flex-end;
}

.chat-message-face.center {
  justify-content: center;
}

.chat-message-face.left {
  justify-content: flex-start;
}

/* ========================
   FILES PANEL
=========================== */

.files-list-li-content {
  background-color: #2d2d2f !important;
  height: 70px;
  display: flex !important;
  padding: 0 !important;
}

.files-list-li-header {
  background-color: #202023 !important;
  height: 70px;
  display: flex !important;
  padding: 0 !important;
}

.files-search-box {
  margin-right: 15px;
}

.files-search-box {
  margin-right: 15px;
  display: flex;
  align-items: center;
  background-color: #2d2d2f;
  border: 0px;
  border-radius: 12px;
  padding: 1px 0px 1px 12px;
}

.files-search-box input {
  background-color: #2d2d2f;
  border: 0px;
  border-radius: 12px;
  color: #fff;
  font-size: 0.8em;
}

.bread-crumbs {
  margin: 10px 0px;
}

.slash-bread-crumbs {
  opacity: 0.5;
}

.previous-bread-crumbs {
  transition: opacity 0.5s ease;
  opacity: 0.5;
}

.previous-bread-crumbs:hover {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.pl-075rem {
  padding-left: 0.75rem !important;
}

.files-icon-container,
.files-action-container {
  padding: 10px 0 !important;
  width: 8%;
  justify-content: center;
  align-content: center;
  display: flex;
  height: 100%;
}

.files-structure-content {
  padding: 10px 20px !important;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1px;
  width: 84%;
}

.files-primary-text,
.files-secondary-text {
  font-weight: 700;
}

.files-ternary-text,
.files-meta-text {
  color: #969697;
  font-size: 0.9em;
}

.files-list-li-header .files-primary-text {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.files-list-li-header .files-secondary-text,
.files-list-li-header .files-ternary-text,
.files-list-li-header .files-meta-text {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.files-list-li-header:hover .files-primary-text {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.files-list-li-header:hover .files-secondary-text,
.files-list-li-header:hover .files-ternary-text,
.files-list-li-header:hover .files-meta-text {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.files-list-li-content:hover .files-icon-container,
.files-list-li-content:hover .files-structure-content {
  background-color: #222223 !important;
}

.file-meta-info {
  border-right: 1px solid rgba(150,150,151,0.41961);
  padding-right: 6px;
  padding-left: 4px;
}

.file-meta-info-last {
  padding-right: 6px;
  padding-left: 4px;
}

/* ========================
   STICKY NAV MIDDLE PANEL
=========================== */
.project-name-sticky .sticky {
  z-index: 102;
}

.sticky .header-middle-panel {
  z-index: 99;
}

.sticky .project-header-container {
  padding: 0;
}

.header-middle-panel {
  z-index: 100;
  padding: 10px 0;
  position: relative;
  background: #212124;
  transition: 0.1s ease-in-out;
}
.header-middle-panel .row {
  background: #212124;
}

#projectHeader {
  color: #ffffff;
  color: var(--White);
  font-weight: 700;
}

.sticky-header {
  z-index: 100;
  position: relative;
  min-height: 0 !important;
}

/* ========================
    MODAL WINDOW
=========================== */
.modal-sm {
  max-width: 328px;
  margin: 0 auto;
}

.modal-open {
  padding-right: 0 !important;
}

html {
  overflow-y: scroll !important;
}
.modal-center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  min-height: 100vh;
}

.delic-modal .modal-content {
  max-width: 500px;
  margin: 0 auto;
  background-color: #222;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  outline: 0;
  box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.9);
  box-shadow: 0 15px 30px -15px var(--opacity-b-09);
}

.delic-modal .modal-header {
  border: none;
  padding: 1.5rem;
  align-items: center;
}

#contained-modal-title-vcenter {
  font-weight: 700;
  font-weight: var(--Bold);
  font-size: 30px;
  color: var(--white);
  margin-bottom: 0;
  width: 100%;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem 1.5rem 2rem;
}

.terms-modal {
  z-index: 1051 !important;
}

.modal-backdrop.show {
  opacity: 0.75;
}

.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.1);
  background-color: var(--opacity-w-01);
}

.modal-header .close {
  padding: 0;
  margin: 0 0 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-image: url(/icons/Icon-Close.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 72%;
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.5);
  background-color: var(--opacity-b-05);
  position: absolute;
  right: 0.75em;
  top: 0.5em;
  z-index: 1;
}

button.close span {
  display: none;
}

.alert {
  z-index: 10001;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
}
.alert>button.close span {
  display: inherit;
}

button.close {
  border-radius: 25px;
}

.full-width-modal.support-feedback-form {
  max-width: 720px;
  min-height: 406px;
}

.delic-modal.full-width-modal .modal-content,
.delic-modal.full-screen-modal .modal-content {
  width: 100% !important;
  max-width: 100%;
  height: auto;
}

body.modal-open #root {
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  filter: blur(10px);
}

/* =========================
    SIMPLE MODAL FULLSCREEN
============================ */
.delic-modal.full-screen-modal {
  background-color: rgba(255, 255, 255, 0.9);
}

.delic-modal.full-screen-modal .modal-dialog-centered,
.delic-modal.full-screen-modal .modal-dialog {
  margin: 0 !important;
  max-width: 100% !important;
}

.delic-modal.full-screen-modal .modal-content {
  position: absolute;
  top: 0;
  bottom: 0;
  background: transparent;
  max-width: 100% !important;
  border: none !important;
  border-radius: 0 !important;
  outline: 0 !important;
  box-shadow: none;
  color: #000000 !important;
  color: var(--Black) !important;
}

@media (max-width: 520px) {
  .full-width-modal .modal-lg,
  .full-width-modal .modal-xl {
    max-width: 100% !important;
  }
}

.events-none {
  pointer-events: none;
}

.eye-dot.dot-on::after {
  background: #0062ff;
  background: var(--Blue);
}
.eye-dot.dot-off::after {
  background: #ff9900;
  background: var(--Orange);
}
.delic-form .rbt-input-hint input {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--opacity-w-05) !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1.14em !important;
  font-weight: 500 !important;
  line-height: 1.78 !important;
}

/* ======================================
INVITATION MANAGER - PENDING INVITATIONS
======================================= */

.ReactModal__Overlay.ReactModal__Overlay--after-open.project-members-manager-overlay {
  background: rgba(255,255,255,0);
  background: var(--Transparent);
}

.slide-pane__overlay.project-members-manager-overlay {
  z-index: 1001;
  width: 100%;
  width: var(--Right-Side-Panel);
  right: 0;
  left: auto;
  top: 3em;
}

.project-members-manager .slide-pane__content {
  position: relative;
  overflow-y: auto;
  flex: 1 1 auto;
  background: #28282c;
  padding: 0 1em 0 !important;
}

.invitee:after {
  content: "";
  width: 16%;
  height: 16%;
  position: absolute;
  background: red;
  border-radius: 50%;
  right: 12%;
  top: 12%;
}

.invitee-btn-dot {
  align-items: center;
}

.invitee-btn-dot span {
  padding-bottom: 0.25em;
}

@media (min-width: 576px) {
  .project-members-manager .slide-pane__content {
    padding: 0 1.5em 0 !important;
  }
}

@media (min-width: 1700px) {
  .slide-pane__overlay.project-members-manager-overlay {
    max-width: 520px;
    width: 100%;
    width: var(--Right-Side-Panel);
  }
}

/*TASK PANEL*/

.scrollable-panel-user-profile {
  overflow-y: auto;
}

.tasks-main-title {
  color: #ffffff;
  color: var(--White);
}

.tasks-container {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
}

.task-list-item {
  margin: 25px 0;
}

.task-list-title {
  display: flex;
  margin: 20px 0 10px;
  height: 32px;
}

.task-list-title h3 {
  font-weight: 700;
  font-weight: var(--Bold);
  font-size: 20px;
  color: #949496;
  color: var(--LightGrey);
  margin-bottom: 0;
}

.task-list-top {
  background-color: rgba(255, 255, 255, 0.1);
  background-color: var(--opacity-w-01);
  border-radius: 0.5em 0.5em 0 0;
}

.task-list-bottom {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: var(--opacity-b-01);
  align-items: center;
  height: 35px;
  border-radius: 0px 0px 0.5em 0.5em;
}
.task-list-bottom button:hover {
  color: #ffffff;
  color: var(--White);
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}
.delic-popover.popover.task-popover {
  min-width: 300px;
}
.member-list-column-tasks {
  height: auto;
  position: relative;
}
.member-list-column-tasks:after {
  position: absolute;
  content: "";
  height: 24px;
  bottom: 0;
  width: 100%;
  background: rgb(34, 34, 34);
  background: linear-gradient(
    0deg,
    rgba(34, 34, 34, 1) 20%,
    rgba(34, 34, 34, 0) 100%
  );
}
.member-list-column-tasks-scroll {
  max-height: 180px;
  height: auto;
  overflow-y: scroll;
  padding-bottom: 0.5rem;
}
.crossed-out {
  text-decoration: line-through;
  color: #949496 !important;
  color: var(--LightGrey) !important;
}

.react-datepicker__close-icon {
  padding: 0 2px 0 0 !important;
}

.react-datepicker__close-icon::after {
  background-color: transparent !important;
  color: #949496 !important;
  color: var(--LightGrey) !important;
  height: 30px !important;
  width: 30px !important;
  font-size: 26px !important;
  display: flex !important;
  justify-content: center;
  margin: auto;
  padding: 0 !important;
}

.nestedNav.nav-link .nav-menu-icon {
  color: #1964ff;
  color: var(--Light-blue);
}

.info-nav-item.nav-link .nav-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
}
.info-nav-item {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--opacity-w-05) !important;
  font-weight: 500 !important;
}
.nav-link span {
  display: flex;
  align-self: center;
}

a.info-nav-item span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.info-nav-item.nav-link:hover,
button.info-nav-item.nav-link:hover,
a.info-nav-item.nav-link:hover {
  color: rgba(255, 255, 255, 0.7) !important;
  color: var(--opacity-w-07) !important;
}

.navbar-dark .nav-link:hover {
  color: #ffffff;
  color: var(--White);
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
  border-left: 8px solid #0062ff;
  border-left: 8px solid var(--Blue);
}

.owner-user-image {
  height: 60px;
}
.owner-user-data {
  padding-bottom: 11px;
  padding-left: 22px;
}

span.owner-user-name {
  font-weight: 700;
  font-weight: var(--Bold);
  line-height: 0.9em;
  padding-bottom: 0.3em;
}

span.owner-user-label {
  color: #949496;
  color: var(--LightGrey);
  line-height: 1.5em;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.loading-centered {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.h-40 {
  height: 40% !important;
}

/* ========================
   FULLWIDTH PANEL CONTAINER
=========================== */
.header-fullwidth-panel {
  z-index: 100;
  padding: 74px 0 10px;
  transition: 0.15s ease-in-out;
}

.background-black {
  background: #212124;
}

.background-white {
  background: white;
}

.background-blue {
  background: #0062ff;
  background: var(--Blue);
}

/* ========================
   FILES PANEL
=========================== */
.files-list-li-content {
  background-color: #2d2d2f !important;
  height: 70px;
  display: flex !important;
  padding: 0 !important;
}
.files-list-li-header {
  background-color: #202023 !important;
  height: 70px;
  display: flex !important;
  padding: 0 !important;
}
.files-search-box {
  margin-right: 15px;
}
.files-search-box {
  margin-right: 15px;
  display: flex;
  align-items: center;
  background-color: #2d2d2f;
  border: 0px;
  border-radius: 12px;
  padding: 1px 0px 1px 12px;
}
.files-search-box input {
  background-color: #2d2d2f;
  border: 0px;
  border-radius: 12px;
  color: #fff;
  font-size: 0.8em;
}

.bread-crumbs {
  margin: 10px 0px;
}

.slash-bread-crumbs {
  opacity: 0.5;
}

.previous-bread-crumbs {
  transition: opacity 0.5s ease;
  opacity: 0.5;
}

.previous-bread-crumbs:hover {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.files-icon-container,
.files-action-container {
  padding: 10px 0 !important;
  width: 8%;
  justify-content: center;
  align-content: center;
  display: flex;
  height: 100%;
}

.files-structure-content {
  padding: 10px 20px !important;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1px;
  width: 84%;
}

.files-primary-text,
.files-secondary-text {
  font-weight: 700;
}

.files-ternary-text,
.files-meta-text {
  color: #969697;
  font-size: 0.9em;
}

.files-list-li-header .files-primary-text {
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.files-list-li-header .files-secondary-text,
.files-list-li-header .files-ternary-text,
.files-list-li-header .files-meta-text {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.files-list-li-header:hover .files-primary-text {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
}

.files-list-li-header:hover .files-secondary-text,
.files-list-li-header:hover .files-ternary-text,
.files-list-li-header:hover .files-meta-text {
  transition: opacity 0.5s ease;
  opacity: 1;
}

.files-list-li-content:hover .files-icon-container,
.files-list-li-content:hover .files-structure-content {
  background-color: #222223 !important;
}

.file-meta-info {
  border-right: 1px solid rgba(150,150,151,0.41961);
  padding-right: 6px;
  padding-left: 4px;
}

.file-meta-info-last {
  padding-right: 6px;
  padding-left: 4px;
}

/* =========================
    JUMBOTRON
============================ */
.public-profile-jumbotron {
  background-color: rgba(255, 255, 255, 0.05);
  background-color: var(--opacity-w-005);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.7);
}

.public-profile-jumbotron .form-group {
  margin-bottom: 0.5rem;
}

[dir="ltr"] ul.howto-list{
  padding-left: 10px;
}

[dir="rtl"] ul.howto-list{
  padding-right: 10px;
}

ul.howto-list {
  list-style: none;
}

.listdotNumber {
  background-color: rgba(0, 0, 0, 0.5);
  background-color: var(--opacity-b-05);
  display: flex;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
  font-weight: 700;
  justify-content: center;
  align-items: center;
  flex: none;
  margin-right: 1em;
  line-height: 0;
}

.form-select-pp {
  background-color: #0062ff;
  background-color: var(--Blue);
  border: 1px solid #0062ff;
  border: 1px solid var(--Blue);
  border-radius: 5px;
  width: 100%;
  color: #ffffff;
  color: var(--White);
}

.delic-form .form-select-pp-grey.form-control {
  margin-bottom: 10px;
  padding: 0 30px;
}

.delic-form .form-control::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
  opacity: 1;
}

.delic-form .form-control::placeholder,
.form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
  opacity: 1;
}

.delic-form .form-select-pp-grey.form-control option {
  background: #413e3e !important;
}

.form-select-pp-grey:focus {
  color: #ffffff;
  color: var(--White);
  background-color: #3e3e41;
  background-color: var(--MiddleGrey);
  border: none;
  box-shadow: inset 0 -2px 0 #2f2f2f;
  box-shadow: inset 0 -2px 0 var(--DarkerGrey);
}

.form-dropdowm-pp.dropdown button {
  border-radius: 5px;
  width: 100%;
}

.form-dropdowm-pp .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 18px;
}

.form-dropdowm-pp.dropdown btn {
  background-color: #0062ff;
  background-color: var(--Blue);
  border: 1px solid #0062ff;
  border: 1px solid var(--Blue);
}

/* =========================
    FORMS
============================ */
.delic-form label.form-label {
  color: #909091;
  font-size: 0.875em;
  letter-spacing: 0.03em;
}

.delic-form input:-internal-autofill-selected {
  background-color: #3e3e41 !important;
  background-color: var(--MiddleGrey) !important;
  background-image: none !important;
  color: #ffffff !important;
  color: var(--White) !important;
}

.delic-form input:-webkit-autofill,
.delic-form input:-webkit-autofill:hover,
.delic-form input:-webkit-autofill:focus,
.delic-form textarea:-webkit-autofill,
.delic-form textarea:-webkit-autofill:hover,
.delic-form textarea:-webkit-autofill:focus,
.delic-form select:-webkit-autofill,
.delic-form select:-webkit-autofill:hover,
.delic-form select:-webkit-autofill:focus {
  -webkit-text-fill-color: #ffffff;
  -webkit-text-fill-color: var(--White);
  box-shadow: 0 0 0px 1000px rgba(255,255,255,0) inset;
  box-shadow: 0 0 0px 1000px var(--Transparent) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  font-size: 16px;
  font-family: sofia-pro, sans-serif;
  font-family: var(--delic-font);
}

.delic-form .rbt-input-hint input {
  color: #fff !important;
  line-height: 1em !important;
  padding: 0.375rem 0.75rem !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.95 !important;
}

.delic-form .edit-task-tile {
  font-weight: 700;
  font-weight: var(--Bold);
  font-size: 20px;
  color: #949496;
  color: var(--LightGrey);
  margin-bottom: 0;
  padding: 0 0.5em !important;
  height: auto !important;
  width: 100%;
}

.delic-form.public-profile .form-control {
  background: #1d1d1d;
  color: #ffffff;
  color: var(--White);
}

.public-profile-text p {
  white-space: pre-line;
}

button.temporary-redirect-btn a {
  color: black;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #0062ff;
  border-left-color: var(--Blue);
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #0062ff;
  border-right-color: var(--Blue);
}

/* SELECT ARROW CROSSBROWSER */
.selectdelic {
  position: relative;
}

.selectdelic:after {
  content: "";
  right: 1.2em;
  top: 0.95em;
  height: 0.875em;
  width: 0.875em;
  position: absolute;
  pointer-events: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyODYuMTMgMTY3LjA1Ij48cGF0aCBkPSJNNDEsNzYuNDhIMjc5YzIxLjQsMCwzMi4xLDI1Ljg5LDE3LDQxbC0xMTksMTE5YTIzLjkyLDIzLjkyLDAsMCwxLTMzLjksMEwyNCwxMTcuNDhDOC45LDEwMi4zNywxOS42LDc2LjQ4LDQxLDc2LjQ4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTE2LjkzIC03Ni40OCkiIHN0eWxlPSJmaWxsOiNmZmYiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 12px;
  opacity: 0.7;
}

/* IE11 hide native button (thanks Matt!) */
select::-ms-expand {
  display: none;
}

.selectdelic select {
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  float: right;
  -ms-word-break: normal;
  word-break: normal;
  cursor: pointer;
}

.social-box-fixed-height {
  height: 42px;
}
.social-box {
  justify-content: flex-end;
  padding: 2em 0 0;
}
.social-box-container {
  padding: 0.5em;
}
.social-box-container span {
  font-size: 0.7em;
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
}
.social-box-icon {
  margin: 5px;
  transition: 0.1s ease-in-out;
  background: rgba(255, 255, 255, 0.1);
  background: var(--opacity-w-01);
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3rem;
}
.social-box-icon:hover {
  opacity: 0.5;
}

@media (max-width: 767px) {
  .social-box {
    justify-content: center;
    padding: 0 0.5em;
  }
}

/* HIDDEN FORM */
form.hidden-form textarea {
  background: rgba(40, 39, 44, 0);
  border: 0px solid rgba(40, 39, 44, 0);
  color: #fff;
  overflow: hidden;
  resize: none;
}

/*  FIELDSET FORMS  */
fieldset.form-columns-1 .input textarea {
  margin-right: 0;
  border-radius: 5px 5px 0 5px !important;
  padding: 10px;
}

/* ==============================
    ROLE MANAGER
============================== */
/* #role-manager-container {
  background: var(--opacity-b-01);
  border-radius: 0 0 10px 10px;
  padding: 0 10px 20px;
  box-shadow: 0 5px 10px var(--opacity-b-01), inset 0 10px 15px 0px var(--opacity-b-01);
} */
#role-manager-container {
  padding: 0 0.5rem 1.5rem;
}
.simple-role {
  min-height: 40px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.05); */
}

.simple-role-user {
  align-items: center;
  height: 40px;
  width: 40%;
}

.simple-role-buttons {
  width: 142px;
}

.simple-role-selector-list {
  width: 60%;
}

.simple-role-selector {
  width: 100%;
}

.simple-role-selector .dropdown {
  width: 100%;
}

button#dropdown-item-button.role-dropdown {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 5px;
  border: 1px solid rgba(255,255,255,0);
  font-size: 12px;
  display: flex;
  height: 32px;
  width: 100%;
  color: lightgrey;
  letter-spacing: 0.02em;
  line-height: 2.2;
}

#add-project-members-typeahead li:hover,
#add-project-members-typeahead li:hover a {
  background: #1964ff;
  background: var(--Light-blue);
  color: #fff;
}

#add-project-members-typeahead li.disabled:hover,
#add-project-members-typeahead li.disabled:hover a {
  background: rgba(255,255,255,0);
  background: var(--Transparent);
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
}

ul#add-project-members-typeahead li {
  padding: 0;
  margin: 0;
}

.dropdown-menu {
  padding: 0;
  margin: 0.125rem 0 0;
  color: var(--white);
  background-color: #303030;
  border-radius: 0.5rem;
  overflow: hidden;
}

.dropdown-menu.show {
  display: block;
  background: #303030;
  box-shadow: 0 15px 20px -15px rgba(0, 0, 0, 0.7);
  box-shadow: 0 15px 20px -15px var(--opacity-b-07);
}

.simple-role-selector .dropdown-menu {
  min-width: 26rem;
  z-index: 10000;
}

.role-dropdown.dropdown-toggle::after {
  position: absolute;
  right: 1em;
  margin-top: 1em;
  color: #949496;
  color: var(--LightGrey);
}

.simple-role-selector .dropdown-item {
  padding: 0.5rem 1.25rem;
  font-size: 0.875em;
  color: rgba(255,255,255,0.65882);
  background-color: transparent;
  min-width: 190px;
}

.dropdown-item:focus,
.dropdown-item:hover {
  text-decoration: none;
  background-color: #1964ff;
  background-color: var(--Light-blue);
  color: white;
}

.alert-dropdown.dropdown-item:focus,
.alert-dropdown.dropdown-item:hover {
  text-decoration: none;
  background-color: #ff3200;
  background-color: var(--Red);
  color: #000000;
  color: var(--Black);
}

.simple-role-selector .dropdown-item:focus,
.simple-role-selector .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}

.name-contributor-role {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name-contributor-role span {
  width: 90%;
}

@media (max-width: 767px) {
  .simple-role-user {
    width: 100%;
  }

  .simple-role-selector-list {
    width: 100%;
  }

  .simple-role-buttons {
    width: 170px;
  }
}

@media (max-width: 520px) {
  .simple-role-selector .dropdown-menu {
    min-width: calc(90vw - 15px);
    -webkit-transform: translate3d(-10px, 35px, 0px);
            transform: translate3d(-10px, 35px, 0px);
  }

  .simple-role-selector .dropdown-item {
    padding: 0.5rem 0.5rem;
    font-size: 0.75em;
    min-width: 145px;
  }
}

/* ==============================
    PRICING PAGE
============================== */
.price-column {
  padding: 14%;
}

ul.pricing-list li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.875em;
  line-height: 1.3em;
}
ul.pricing-list {
  scroll-padding-inline-start: 0;
}
.text-line-through {
  text-decoration: line-through;
}
.best-value-pill {
  color: white;
  background: #000000;
  padding: 0.5em 2rem;
  border-radius: 0 0 0.5rem 0.5rem;
  position: absolute;
  top: 185px;
  right: -59px;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  font-weight: bold;
}
.delic-pill-pricing {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border: 1px solid var(--opacity-w-05);
}
.delic-pill-pricing:hover {
  background: rgba(255, 255, 255, 0.3);
  background: var(--opacity-w-03);
  color: rgba(255, 255, 255, 0.9);
  color: var(--opacity-w-09);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border: 1px solid var(--opacity-w-03);
}
.delic-pill-pricing.active {
  background: #0000fa;
  background: var(--Rich-blue);
  color: #fff;
  border: 1px solid #0000fa;
  border: 1px solid var(--Rich-blue);
}
@media (max-width: 1340px) {
  .price-column {
    padding: 14% 6%;
  }
}
@media (max-width: 1200px) {
  .price-column {
    padding: 2rem;
  }
  .best-value-pill {
    padding: 0.24rem 2rem;
    border-radius: 25px;
    position: relative;
    top: 0;
    left: 0;
    margin-left: 0;
  }
  ul.pricing-list li {
    font-size: 0.75em;
  }
  .price-column .f-sz-70 {
    font-size: 3.375em !important;
  }
}
@media (max-width: 720px) {
  .price-column {
    padding: 1.5rem;
  }
}

/* ==============================
    DELIC POPOVER
============================== */
.popover-header {
  background-color: transparent;
  border-bottom: none;
}

.delic-popover.popover {
  border: 0;
  border-radius: 14px;
  background: #222;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  box-shadow: 0 5px 15px var(--opacity-b-05);
}

.delic-popover h3.popover-header {
  color: #949496;
  color: var(--LightGrey);
  border-bottom: 0;
}

.delic-popover .popover-body {
  padding: 1rem 0.75rem;
  color: #949496;
  color: var(--LightGrey);
  border-radius: 0 0 10px 10px;
}

.delic-popover.bs-popover-bottom > .arrow::after,
.delic-popover.bs-popover-bottom > .arrow::before {
  top: 1px;
  right: 6px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #212123;
}

.delic-popover.bs-popover-bottom .popover-header::before {
  border-bottom: 0;
}

.delic-popover.bs-popover-bottom > .arrow {
  top: calc(-0.5rem - 0px);
}

.delic-popover .dropdown-item {
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
  padding: 0.875em 0.25em;
}

.delic-popover li {
  margin-left: 0;
  padding: 0 0.25em;
}

.delic-popover .dropdown-item:focus,
.delic-popover .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #1964ff;
  background-color: var(--Light-blue);
}

/* ==============================
    DELIC POPOVER
============================== */
.delic-popover-info,
.delic-popover-dark {
  border-radius: 5px;
  max-width: 14rem;
  z-index: 10000;
}
.delic-popover-green {
  border-radius: 5px;
  max-width: 22rem;
  z-index: 10000;
  background: #28c882;
  background: var(--Light-Green);
}
.delic-popover-info-big {
  border-radius: 5px;
  max-width: 21rem;
  z-index: 10000;
}

.delic-popover-info,
.delic-popover-info .popover-header {
  background: #0000fa;
  background: var(--Rich-blue);
}
.delic-popover-dark,
.delic-popover-dark .popover-header {
  background: #2f2f2f;
  background: var(--DarkerGrey);
}
.delic-popover-green,
.delic-popover-green .popover-header {
  background: #28c882;
  background: var(--Light-Green);
}
.delic-popover-info-big,
.delic-popover-info-big .popover-header {
  background: #0000fa;
  background: var(--Rich-blue);
}

.acorn-target {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.delic-popover-orange {
  border-radius: 5px;
  max-width: 22rem;
  z-index: 10000;
  background: #ff9900;
  background: var(--Orange);
}

.delic-popover-orange .popover-header {
  background: #ff9900;
  background: var(--Orange);
}

@media (max-width: 720px) {
  .delic-popover-green {
    max-width: 19rem;
  }
  .bs-popover-auto[x-placement^="bottom"] .popover-header::before,
  .bs-popover-bottom .popover-header::before {
    border-bottom: 1px solid rgba(255,203,0,0);
  }
  .delic-popover-green .arrow {
    display: none;
  }
}

.delic-popover-info .popover-header,
.delic-popover-dark .popover-header,
.delic-popover-green .popover-header {
  border: 0;
  padding: 0.75rem 0.75rem;
  font-weight: bold;
  font-size: 1.125em;
}

.delic-popover-info .popover-body,
.delic-popover-dark .popover-body,
.delic-popover-green .popover-body {
  padding: 0 0.75rem 0.75rem;
  color: #ffffff;
}

.delic-popover-info .arrow,
.delic-popover-yellow .arrow,
.delic-popover-green .arrow,
.delic-popover-dark .arrow {
  display: none;
}

.delic-popover-info .arrow::after,
.delic-popover-info .arrow::before,
.delic-popover-green .arrow::after,
.delic-popover-green .arrow::before,
.delic-popover-dark .arrow::after,
.delic-popover-dark .arrow::before {
  display: none;
}

.delic-tooltip.tooltip {
  font-size: 1rem;
  opacity: 1;
}
.delic-tooltip .tooltip-inner {
  max-width: inherit;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  background-color: var(--opacity-0);
  border-radius: 0.25rem;
}
.delic-tooltip.tooltip .arrow::before {
  display: none;
}
/* ==============================
    LOGIN PAGE
============================== */
.login-page.container {
  margin-top: 4%;
}

.login-box {
  background: #28282c;
  box-shadow: 0px 20px 30px -10px rgba(0,0,0,0.27059);
  padding: 0px;
  border-radius: 15px;
}

.login-box .row {
  margin: 0px;
}

.auth-box-container {
  padding: 20px 20px;
}

.login-form-message {
  color: #909091;
  text-align: center;
  margin: 0px 10px 20px;
}

.login-box-form-separator-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0px;
}

.login-box-form-separator {
  width: 40%;
  height: 3px;
  background-color: #3e3e41;
  border-radius: 10px;
}

.login-box-form-or {
  width: 14%;
  text-align: center;
  font-size: 12px;
  color: #909091;
}

p.login-box-error-message {
  text-align: center;
  color: #ff3200;
  color: var(--Red);
  margin-bottom: 4px;
}

/* ==============================
    TASKS FORM -  DATE PICKER
============================== */
.react-datepicker-wrapper {
  display: flex !important;
  padding: 0;
  border: 0;
}

.react-datepicker-wrapper input {
  height: 40px;
  font-size: 0.875rem;
}

.delic-form .form-control {
  min-height: 40px;
}

.delic-form .form-control,
.delic-form .delic-form-date {
  border: none;
  background-color: rgba(255, 255, 255, 0.025);
  background-color: var(--opacity-w-0025);
  color: #ffffff;
  color: var(--White);
  transition: 0.1s ease-in-out;
}

.delic-form .form-control:hover,
.delic-form .delic-form-date:hover {
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
}

textarea.form-control {
  height: auto;
  min-height: 40px;
}

#edit-task--title {
  font-weight: 700;
  color: #ccc;
}

textarea:focus,
input:focus {
  outline: none;
}
.form-control:focus {
  box-shadow: inset 0 -3px 0 #0062ff;
  box-shadow: inset 0 -3px 0 var(--Blue);
  border-radius: 0.2rem 0.2rem 0 0;
}
input.delic-form-date {
  padding: 0 10px;
  border-radius: 5px;
  width: 100%;
}
.react-datepicker {
  background: #181818;
}
.react-datepicker__header {
  text-align: center;
  background-color: #181818;
  border-bottom: 1px solid #3e3e41;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding-top: 8px;
  position: relative;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 1rem;
  background-color: #3662ff;
  color: #fff;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #ffffff;
  color: var(--White);
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}
.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  color: #000;
  border: 1px solid #2f2f2f;
  border: 1px solid var(--DarkerGrey);
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
  box-shadow: 0 0 60px -15px black;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #ffffff;
  color: var(--White);
  font-weight: inherit;
  font-size: 0.875em;
  letter-spacing: 0.02em;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  border-radius: 0.3rem;
  background-color: #3e3e41;
  background-color: var(--MiddleGrey);
}
.react-datepicker__triangle {
  display: none;
}
/* CREATABLE */
.creatable {
  position: relative;
}

/* ==============================
    EDIT TASK PANEL
============================== */
.edit-task-inner {
  padding: 5%;
}

.task-list-li .reference-comment {
  padding: 0;
}

.task-messages {
  background: rgba(0, 0, 0, 0.1);
  background: var(--opacity-b-01);
}

button.close.rbt-token-remove-button span {
  display: block;
  position: relative;
  left: -4px;
  top: 3px;
}

.rbt-token .rbt-token-remove-button {
  bottom: 0;
  color: inherit;
  font-size: inherit;
  font-weight: normal;
  opacity: 1;
  outline: none;
  padding: 3px 7px;
  position: absolute;
  right: 0;
  text-shadow: none;
  top: -7px;
  right: -4px;
}

/* ==================================
  WORKS PANEL - FILES
==================================== */
.delic-modal.import-files-modal .modal-content {
  max-width: 560px !important;
}

.file-list-li.accordion {
  z-index: 1;
  position: relative;
  border-radius: 22px;
  background: red;
}

.list-group-item {
  border-radius: 0.5em;
  background: rgba(0, 0, 0, 0);
  background: var(--opacity-0);
  border: none;
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  margin-bottom: 0px;
}

.grid-livestream .reference-wrap {
  max-height: 160px;
}

.file-message {
  background: rgba(0, 0, 0, 0.2);
}

button.list-item-btn {
  height: 48px;
}
.list-item-title {
  word-break: break-all;
}
.list-item-title span {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.25;
  font-weight: 500;
}

.list-folder-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  max-width: 18rem;
}
@media (min-width: 920px) {
  .list-folder-title {
    max-width: 40rem;
  }
}
.card {
  border-radius: 0;
}
.work-card.card {
  border: 0;
  background: transparent;
  border-radius: 0.5em;
}

.work-messages {
  background: rgba(0, 0, 0, 0.1);
  background: var(--opacity-b-01);
}

.work-card.card .card-header {
  padding: 0;
  cursor: auto;
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
  border-radius: 0.5rem;
}

.selected-anchor .card-header {
  background: #0062ff !important;
  background: var(--Blue) !important;
}

.cursor-auto {
  cursor: auto;
}

.work-card.card .card-body {
  background: #efefef;
}

.work-bottom-info {
  background: rgba(0, 0, 0, 0.1);
  background: var(--opacity-b-01);
  border-radius: 0 0 8px 8px;
  box-shadow: inset 0 10px 15px -10px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 10px 15px -10px var(--opacity-b-01);
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.selected-anchor .work-name-info {
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
}

.work-name-title {
  display: block;
  padding-bottom: 0.2em;
  line-height: 1.3em;
  font-weight: 500;
  word-break: break-all;
}
.work-name-title.text-truncate:after {
  content: "";
  position: absolute;
  width: 29px;
  height: 100%;
  z-index: 1;
  top: 0.2rem;
  right: 0rem;
  background: rgb(44, 44, 47);
  background: linear-gradient(
    90deg,
    rgba(44, 44, 47, 0.4682247899159664) 0%,
    rgba(44, 44, 47, 1) 100%
  );
}
.work-list-title h5 {
  font-weight: 700;
  font-weight: var(--Bold);
  font-size: 1.25em;
  color: rgba(255, 255, 255, 0.5);
  color: var(--opacity-w-05);
  margin-bottom: 0;
}
.work-component-icons-date-wrapper {
  justify-content: center;
  align-items: flex-end;
  display: flex;
  padding: 0 0.5rem 0.25rem 1rem;
  width: 55%;
}

.separator-icon-component {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
  border-left: 1px solid var(--opacity-w-005);
}
.delic-form.edit-work-title .form-control {
  border: none;
  background: #3e3e41;
  background: var(--MiddleGrey);
  color: #ffffff;
  color: var(--White);
  font-size: 1.25em;
}
#upload-work {
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
}

.edit-component-inner {
  padding: 1rem 1.25rem 1.25rem;
  background: rgba(255,255,255,0);
  background: var(--Transparent);
}

@media (max-width: 767px) {
  .edit-component-inner {
    padding: 1rem 0.75rem;
  }
  .work-component-icons-date-wrapper {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .work-component-title-wrapper {
    width: 100%;
  }
}

.work-page-not-playable-preview {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border: 1px solid var(--opacity-w-005);
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-radius: 50px;
  color: #949496;
  color: var(--LightGrey);
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
}

.work-page-alert-notice {
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #949496;
  border: 1px solid var(--LightGrey);
  padding: 0 10px;
  border-radius: 50px;
  color: #949496;
  color: var(--LightGrey);
  margin-top: 8px;
}

.work-page-alert-notice-icon {
  margin: 0px 15px 0 10px;
}

.work-page-alert-notice-message {
  text-align: center;
  font-size: 12px;
}

.upload-version-different-format-container {
  padding: 10px;
  border: 1px solid #00b092;
  border: 1px solid var(--Green);
  border-radius: 6px;
  color: #949496;
  color: var(--LightGrey);
  display: flex;
  font-size: 14px;
  margin: 0 15px 15px;
  align-items: center;
}

.upload-version-different-format-icon {
  color: #949496;
  color: var(--LightGrey);
  margin-right: 10px;
}

/* ==================================
    WORKS DASHBOARD - GRID
==================================== */

.dashboard-files-grid {
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 0;
  grid-template-columns: repeat(1, 1fr);
}
@media (min-width: 1300px) {
  .dashboard-files-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1800px) {
  .dashboard-files-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* ==================================
  PROGRESS BAR - PLAYER
==================================== */

.bar-container {
  margin-top: 16px;
}

.delic-progress {
  height: 1em;
  width: 100%;
  position: relative;
  border-radius: 0.5rem;
}

.delic-progress .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
  border-radius: 0.5rem;
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  border-radius: 0.5rem;
  margin-top: 0.5em;
}

.delic-progress .progress-bar {
  border-radius: 0.5rem;
}

.upload-file-box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  border-bottom: 1px solid var(--opacity-w-005);
  margin: 0.25em 0;
}

audio.delic-player {
  height: 42px;
}

/* ==================================
    SIMPLE PLAYER
==================================== */
#simple-player {
  background: #0062ff;
  background: var(--Blue);
  height: 48px;
  padding: 0 1em;
}

#simple-player input {
  width: 100%;
}

[type="range"] {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 0.25em;
  background: transparent;
  font: 1em/1 arial, sans-serif;
  border-radius: 5px;
  cursor: pointer;
}

[type="range"],
[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

[type="range"]::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  height: 0.25em;
  background: rgba(204, 204, 204, 0.2);
  border-radius: 5px;
}

[type="range"]::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.25em;
  background: #ccc;
  border-radius: 5px;
}

[type="range"]::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.25em;
  background: #ccc;
  border-radius: 5px;
}

/* progress done input slider */
[type="range"]::-moz-range-progress {
  height: 0.25em;
  border-radius: 5px;
}

[type="range"]::-ms-fill-lower {
  height: 0.25em;
  border-radius: 5px;
}

/* Bullet input slider */
[type="range"]::-webkit-slider-thumb {
  margin-top: -0.25em;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
  background: var(--opacity-b-09);
}

[type="range"]::-moz-range-thumb {
  margin-top: -0.25em;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
  background: var(--opacity-b-09);
}

[type="range"]::-ms-thumb {
  margin-top: -0.25em;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.2);
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.9);
  background: var(--opacity-b-09);
}

[type="range"]::-ms-tooltip {
  display: none;
}

.audio-player-blue [type="range"]::-webkit-slider-thumb,
.audio-player-blue [type="range"]::-moz-range-thumb,
.audio-player-blue [type="range"]::-ms-thumb {
  background: #ccc;
}

/* ==================================
    USER PROFILE == SIDE PANEL == GRID
==================================== */

.grid-livestream .reference-wrap {
  max-height: 160px;
}

.grid-livestream {
  width: 100%;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  padding-bottom: 2rem;
}

.public-previous-live-icon {
  position: absolute !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 1000 !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
  border-radius: 10px;
}

.public-previous-live-stream {
  max-width: 100%;
  max-height: 300px;
}

.live-instructions {
  color: #ffffff;
  color: var(--White);
}

.instructions-mobile-button {
  width: 42px;
}

.instructions-separator {
  height: 1px;
  background: #2f2f2f;
  background: var(--DarkerGrey);
  margin: 1rem 0;
}

.container-live {
  margin: 10px 0px 5px;
  justify-content: space-between;
}

.container-live-info {
  font-size: 14px;
  color: #949496;
  color: var(--LightGrey);
}

.live-notify-box {
  padding: 0 0.5rem 0 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin-right: 5px;
}

.live-notify-dot {
  display: flex;
  height: 100%;
  align-items: center;
}

span.live-notify-message {
  padding-left: 0.25rem;
  height: 26px;
  align-items: center;
}

/* ==========================================
SIDE PANEL /// USER PROFILE
=========================================== */
.scroll-container-user {
  width: 100%;
  padding-bottom: 2rem;
  bottom: 0;
  left: 0;
}

.scroll-container-user p {
  white-space: pre-line;
}

.fakebox {
  width: 49%;
  background: #1a1a1d;
  height: 120px;
  margin: 0 1% 1% 0;
  border-radius: 10px;
}

.image-profile-container {
  height: 50px;
}

.user-pro-bg-container {
  height: 30vh;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  background: var(--opacity-b-005);
}

.user-pro-bg-img {
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
  height: 100%;
  opacity: 0.9;
}

h3.user-pro-bg-name {
  font-size: 1.6em;
  font-weight: 700;
  font-weight: var(--Bold);
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 1.4em;
  text-align: left;
}

.user-pro-profile-img {
  position: absolute;
  z-index: 100;
  top: -40px;
  left: calc(50% - 40px);
}

.user-pro-bg-gradient {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  background: #020024;
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.05) 0,
    rgba(0, 0, 0, 0.3) 90%
  );
  background: radial-gradient(
    circle,
    var(--opacity-b-005) 0,
    var(--opacity-b-03) 90%
  );
}
.user-pro-bg-label {
  color: #c3c3c3;
  font-weight: 900;
}

/* ==================================
SLIDER PROJECTS == SLICK SLIDER
==================================== */

.project-slider .slick-track {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.slick-slider.project-slider.slick-initialized {
  position: relative;
}

.slick-slider.project-slider {
  overflow: hidden;
}

.project-slider .slick-slide {
  display: flex !important;
  cursor: -webkit-grabbing;
}

.slick-slider.project-slider {
  overflow: initial;
}

.project-slider .slick-list {
  overflow: initial;
}

.project-slide-img {
  object-fit: cover;
  height: 130px;
}

span.project-slide-title {
  margin: 0 10px;
  font-weight: 700;
  font-weight: var(--Bold);
  color: #ffffff;
  color: var(--White);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5em;
  transition: 0.1s ease-in-out;
}

span.project-slide-text {
  font-size: 0.875em;
  color: #ffffff;
  color: var(--White);
  opacity: 0.7;
  margin: 0 0 10px 10px;
}

.project-slide-link {
  margin-top: 10px;
}

.project-slide-link button:nth-child(2) {
  margin-left: 15px;
}

.project-slider .slick-slide {
  display: flex !important;
}

.project-slider .slick-dots li button:before {
  content: "•";
  opacity: 0.9;
  color: #3662ff;
}

.project-slider .slick-dots li.slick-active button:before {
  opacity: 0.6;
  color: white;
}

.project-slider .slick-dots {
  position: relative;
  bottom: auto;
  margin: 20px 0 0;
}

.project-slide-content {
  bottom: 0;
  position: absolute;
  padding: 0 5px;
  max-width: 100%;
}

/* ==================================
    GRID PROJECTS ==
==================================== */

.projects-grid {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  overflow: hidden;
  transition: 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.project-grid-box {
  background: rgb(0, 0, 250);
  height: 136px;
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.project-grid-img {
  object-fit: cover;
  max-width: 100%;
  object-position: center;
  min-height: 140px;
  height: auto;
}

span.project-grid-title {
  margin: 0 10px 5px;
  font-weight: 700;
  font-weight: var(--Bold);
  color: #ffffff;
  color: var(--White);
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  transition: 0.1s ease-in-out;
}

.gridMask {
  width: 100%;
  height: 140px;
  position: absolute;
  top: 0;
  transition: 0.1s ease-in-out;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

span.project-grid-text {
  font-size: 0.875em;
  color: #ffffff;
  color: var(--White);
  opacity: 0.7;
  margin: 0 0 10px 10px;
}

.project-slide-image-box {
  height: 136px;
  width: 194px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 10px 10px 0;
  cursor: pointer;
  position: relative;
}

.project-slide-container img {
  transition: 0.1s ease-in-out;
}

.project-slide-container:hover img {
  opacity: 0.5;
}

.project-slide-container:hover span.project-grid-title {
  font-weight: 700;
  font-weight: var(--Bold);
  color: #ffffff;
  color: var(--White);
  white-space: normal;
  overflow: hidden;
  text-overflow: unset;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.delic-projects-grid-collapse.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0;
}

.delic-projects-grid-collapse.card {
  background-color: rgba(32,32,36,0);
  border: 0;
}

.delic-projects-grid-collapse.collapsing {
  min-height: 140px;
}

.delic-projects-grid-collapse.collapse:not(.show) {
  display: block;
  height: 140px;
  min-height: 140px;
  max-height: 140px;
}

.card-header {
  padding: 0.75rem 0.1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 0 solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

h2.create-project-button,
.create-project-button img {
  cursor: pointer;
}

/* ================================================
    GRID LIVESTREAMS - PUBLIC PROFILE - SIDE PANEL
==================================================== */

.public-previous-live-stream {
  max-width: 100% !important;
  max-height: 160px !important;
}
.public-previous-live-stream .fb-video {
  max-width: 100% !important;
  max-height: 300px !important;
}
.fb-video ._1o0y {
  opacity: 0 !important;
}
.fb_iframe_widget_fluid {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 400px !important;
}
.fb_iframe_widget_fluid ._1o0y {
  opacity: 0 !important;
}
.fb_iframe_widget_fluid span {
  width: 100%;
  margin-top: -56px;
}
#public-profile-container .fb-reference-wrap .fb_iframe_widget_fluid span {
  width: 100%;
  margin-top: -220px;
  margin-right: -20px;
}
.fb-reference-wrap {
  border-radius: 10px;
  overflow: hidden;
  width: 100% !important;
  height: auto !important;
  max-height: 320px;
}
.public-previous-live-wrap {
  width: 100%;
  padding: 0;
  background: #19191d;
  overflow: hidden;
  border-radius: 14px;
  max-height: 160px !important;
}
.fb-reference-wrap.undefined.wrap-fit {
  max-height: inherit;
}
.fb_iframe_widget_fluid_desktop iframe {
  min-width: auto !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

/* CSS Probably not affecting -
to be deleted when grid livestream  takes screenshot*/
.fb-reference-wrap ._3htz {
  height: 100%;
  left: 0px;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: calc(-75% + 320px) !important;
  width: 100%;
}
.go-to-project svg {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
}

/* ==================================
    HEADROOM
==================================== */
.headroom-wrapper {
  position: relative;
}
.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.headroom--scrolled {
  transition: -webkit-transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out;
  transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
}
.headroom--unfixed {
  position: relative;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  padding: 0;
}
.headroom--unpinned {
  position: fixed;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  padding: 0 1em;
}
.headroom--pinned {
  position: fixed;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  padding: 0 1em;
}
.headroom--unpinned .header-middle-panel,
.headroom--pinned .header-middle-panel,
.headroom--unfixed .header-middle-panel {
  padding: 0 0 1em;
}
/* ==================================
    SLIDE UP RIGHT PANEL
==================================== */

#side-panel-slideUp-container {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: -110%;
  z-index: 106;
  background: #28272c;
  transition: 0.5s cubic-bezier(0.45, -0.01, 0, 1);
  cursor: pointer;
  overflow: scroll;
  padding-bottom: 2rem;
}

#side-panel-slideUp-container.slide-up-rightPanel {
  -webkit-transform: translateY(-110%);
          transform: translateY(-110%);
}

@media (max-width: 767px) {
  #side-panel-slideUp-container {
    padding-bottom: 5rem;
  }
}

.file-uploader {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3e3e41;
  border: 1px solid var(--MiddleGrey);
  border-radius: 25px;
  background: #000;
}

.file-uploader-content:hover {
  background: rgba(0, 0, 0, 0.3);
  background: var(--opacity-b-03);
}

.file-uploader-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.75rem;
  background: rgba(255, 255, 255, 0.025);
  background: var(--opacity-w-0025);
  cursor: pointer;
  height: calc(var(--transfer-height) - 4em - 140px);
  margin-top: 0.5rem;
}
@media (max-width: 767px) {
  .file-uploader-content {
    height: calc(90vh / 2.8);
  }
}

.delic-form .idea-uploader.form-control::-webkit-input-placeholder {
  color: white !important;
}

.delic-form .idea-uploader.form-control::placeholder {
  color: white !important;
}

.wrap-resize-dragger {
  height: 80vh;
  padding-bottom: 12vh;
  padding-top: 20px;
}

.wrap-resize-dragger .ReactCrop {
  margin-top: 2px;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .wrap-resize-dragger {
    height: 100%;
  }
}

/* ==================================
    TOOL TIPS  ==
==================================== */
.delic-sm-tooltip > .tooltip-inner {
  color: #fff;
  font-size: 0.875em;
  letter-spacing: 0.03em;
  box-shadow: 0 4px 10px -5px rgba(0,0,0,0.6);
}

/* Tooltip on top */
.delic-sm-tooltip > .tooltip.top > .tooltip-arrow {
  border-top: 5px solid #3e3e41;
  border-top: 5px solid var(--MiddleGrey);
}

/* Tooltip on bottom */
.delic-sm-tooltip > .tooltip.bottom > .tooltip-arrow {
  border-bottom: 5px solid #3e3e41;
  border-bottom: 5px solid var(--MiddleGrey);
}

/* Tooltip on left */
.delic-sm-tooltip > .tooltip.left > .tooltip-arrow {
  border-left: 5px solid #3e3e41;
  border-left: 5px solid var(--MiddleGrey);
}

/* Tooltip on right */
.delic-sm-tooltip > .tooltip.right > .tooltip-arrow {
  border-right: 5px solid #3e3e41;
  border-right: 5px solid var(--MiddleGrey);
}

/* =================================
    PRIVACY POLICY PAGE
================================= */
.light-mode .header-fullwidth-panel {
  z-index: 100;
  padding: 32px 0 10px;
  background: #ffffff;
}

#privacy-policy p {
  margin-top: 0;
  margin-bottom: 0.5rem;
  max-width: 40rem;
  margin-left: 2.8em;
}

#privacy-policy ul {
  margin-top: 0;
  margin-bottom: 1rem;
  max-width: 40rem;
  margin-left: 2.8em;
  margin-left: 2.2em;
  list-style-position: inside;
}

#privacy-policy h4 span {
  width: 32px;
  height: 32px;
  display: inline-block;
  border-radius: 25px;
  line-height: 1.3em;
  text-align: center;
  margin-right: 0.5em;
}

.reduced-li li {
  margin-bottom: 0;
  padding-bottom: 0.25em;
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.5em;
  padding-left: 1em;
  line-height: 1.5;
}

.readmore-indent {
  margin-left: 2.8em;
}

.topics-ul li {
  list-style: none;
  padding-bottom: 0.5em;
}

a.link-topic {
  cursor: pointer;
  color: #0062ff;
  color: var(--Blue);
}

@media (max-width: 575px) {
  #privacy-policy p,
  #privacy-policy ul,
  .readmore-indent {
    margin-left: 0;
  }

  #privacy-policy h4 span {
    margin-right: 0.25em;
    width: auto;
  }

  [dir="ltr"] #privacy-policy ul{
    padding-left: 1em;
  }

  [dir="rtl"] #privacy-policy ul{
    padding-right: 1em;
  }

  [dir="ltr"] ul.topics-ul{
    padding-left: 0 !important;
  }

  [dir="rtl"] ul.topics-ul{
    padding-right: 0 !important;
  }

  ul.topics-ul {
    margin: 0;
    padding: 0;
  }
}

#privacy-policy p.intro {
  margin-left: 0;
}

.cookie-consent-container {
  align-items: center;
  background: rgb(0, 98, 255);
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  left: 0px;
  position: fixed;
  width: 100%;
  z-index: 1005;
  bottom: 0px;
}

.cookie-consent-content {
  margin: 15px;
  text-align: center;
}

.cookie-consent-content-secondary {
  font-size: 14px;
  margin: 0;
}

.cookie-consent-content a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: underline !important;
}

@media (min-width: 720px) {
  .cookie-consent-button-wrapper {
    margin-left: 30px;
  }
}

.cookie-consent-approve-button {
  background: rgb(255, 255, 255);
  border: 0px;
  border-radius: 50px;
  box-shadow: none;
  color: rgb(0, 98, 255);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 3em;
  margin: 15px 5px;
  font-size: 14px;
}

.cookie-consent-decline-button {
  background: rgba(31, 138, 112, 0);
  border: none;
  border-radius: 50px;
  box-shadow: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 3em;
  margin: 15px 5px;
  font-size: 14px;
}

/* ==================================
    SIMPLE SWITCH
==================================== */
.simple-switch-container {
  display: flex;
}

span.simple-switch-text {
  margin: auto;
  padding: 0 8px;
}

.custom-control.custom-switch {
  width: 50px;
  padding: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  height: 24px;
  width: 50px;
  left: 0;
}

.custom-switch .custom-control-label::before {
  left: 0;
  width: 50px;
  height: 24px;
}

.custom-switch .custom-control-label::before {
  border-radius: 1.5rem;
}

.custom-switch .custom-control-label::after {
  top: 2px;
  left: 2px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.custom-control-label::before {
  position: absolute;
  top: 0;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 600px;
  }
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-height: 992px) {
  :root {
    --NavUser: 160px;
  }

  .navbar-user {
    height: 160px;
  }

  .owner-user-image {
    height: 36px;
  }
}

@media (max-height: 800px) and (orientation: landscape) {
  :root {
    --NavLogo: 100px;
  }
  .navbar-logo {
    height: 160px;
    height: var(--NavLogo);
  }
  .navbar-expand .nav-link {
    min-height: 40px;
  }
  .nav-link,
  .nav-item-like {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .navbar-user {
    padding: 0.75em 1em !important;
  }
  button.navbar-user-btn {
    position: absolute;
    top: -40px;
    left: calc(50% - 30px);
  }
  .owner-user-data {
    padding-bottom: 4%;
    padding-top: 4%;
    min-height: 60px;
  }
  .owner-user-image {
    height: 60px;
  }
  .navbar-logo {
    height: 160px;
    height: var(--NavLogo);
  }
  .slide-pane__overlay.menu-nav-settings {
    height: calc(100% - 330px);
    top: 130px;
  }
}

@media (min-width: 320px) {
  .h2,
  h2 {
    font-size: 1.6rem;
    font-weight: 700;
    font-weight: var(--Bold);
  }
  .h3,
  h3 {
    font-size: 1.5rem;
  }
  .project-header-container {
    width: 100%;
    position: relative;
    margin: 0 auto;
    max-width: 600px;
    z-index: 105;
    background: #202024;
    transition: 0.1s ease-in-out;
  }
  .header-middle-panel {
    padding: 5px 0;
  }
  .navbar {
    padding: 0;
    height: 100%;
  }
  .temporary-mobNav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #2f2f2f;
    background: var(--DarkerGrey);
    height: 50px;
    z-index: 102;
  }
  .public-person-editor {
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }

  /* =======================================
  TASKS PANEL
  ========================================  */

  .add-btn {
    z-index: 999;
    height: 50px;
    width: 50px;
  }
  .add-btn:hover {
    box-shadow: 0 5px 10px rgba(0,0,0,0.67843);
    background: #1c73ff;
  }
  .add-btn-tasks {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .tasks-list-container {
    overflow: scroll;
    position: static;
    height: calc(100vh - 100px);
    scrollbar-width: none;
    padding-bottom: 3rem;
  }

  /* GRID PROJECTS */
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .project-grid-box {
    max-width: initial;
  }

  /* REFERENCES */
  .reference-comment {
    margin: 0;
    padding: 0;
  }
  .reference-wrap {
    background: #19191c;
  }
  .reference-image {
    max-height: 200px;
  }

  .user-pro-bg-details {
    width: 100%;
    height: 100%;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
    padding: calc(30vh / 4) 0;
    justify-content: flex-end;
  }
  .public-profile-jumbotron {
    height: auto;
    min-height: 300px;
  }

  .collapsed-add-comment-form.collapse.show,
  .collapsed-add-comment-form.collapsing {
    padding: 0 0 10px 0;
  }
}

@media (min-width: 568px) {
  /* GRID PROJESTS */
  .projects-grid {
    grid-template-columns: repeat(3, 1fr);
  }

  /* REFERENCES  */
  .reference-comment {
    padding: 0 0 0 10px;
  }

  .collapsed-add-comment-form.collapse.show,
  .collapsed-add-comment-form.collapsing {
    padding: 0 0 10px 0;
  }
}

@media (min-width: 768px) {
  .h2,
  h2 {
    font-size: 1.785rem;
    font-weight: 700;
    font-weight: var(--Bold);
  }

  .h3,
  h3 {
    font-size: 1.75rem;
  }

  .info-nav-btn {
    font-size: initial;
  }

  .header-middle-panel {
    padding: 5px 0;
  }
  .navbar {
    height: 100%;
  }

  .Empty-Gap {
    height: 20px;
  }

  .menuSlide-btn {
    top: 0;
  }

  .tasks-list-container {
    padding-right: 15px;
    padding-bottom: 3rem;
  }
  .reference-wrap {
    max-height: 300px;
  }

  .reference-image {
    max-height: 300px;
  }

  .project-header-container {
    max-width: 690px;
  }

  .temporary-mobNav {
    display: none;
  }
}

@media (min-width: 1300px) {
  .projects-grid {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

@media (min-width: 1400px) {
  .projects-grid {
    grid-template-columns: repeat(5, 1fr) !important;
  }
}

@media (min-width: 1500px) {
  .projects-grid {
    grid-template-columns: repeat(6, 1fr) !important;
  }
}

@media (min-width: 1700px) {
  .projects-grid {
    grid-template-columns: repeat(7, 1fr) !important;
  }
}

/* ==================================
  WAVEFORM PLAYER
==================================== */

.waveformContainer {
  background: #0062ff;
  padding: 0 1.5em;
  overflow: hidden;
  height: 100%;
  font-size: 12px;
  border-radius: 0 0 0.5em 0.5em;
  cursor: pointer;
}
.waveformContainer {
  height: 0;
  transition: 0.15s cubic-bezier(0.4, 0, 1, 1);
  overflow: hidden;
}
.waveformContainer.show {
  height: 48px;
}

.waveform {
  overflow: hidden;
  height: 32px;
  width: 100%;
  min-width: 100%;
  position: relative;
}
.waveform:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  top: 16px;
  border-bottom: 1px dashed rgba(255,255,255,0.23922);
}
.waveform wave {
  position: absolute !important;
  width: 100%;
  overflow-x: hidden !important;
  overflow-y: visible !important;
  overflow: hidden visible !important;
}

.waveform-time {
  width: 72px;
  align-items: center;
}
.loader-waveform {
  position: absolute;
}

/* ==================================
    SIMPLE DELIC LOADING SPINNER
==================================== */
.small-loading-spinner {
  width: 1.5rem;
  height: 1.5rem;
}

/* ==========================================
    SLIDE PANE
========================================== */
.slide-pane {
  background: rgba(255,255,255,0) !important;
  background: var(--Transparent) !important;
}
.slide-pane__content {
  position: relative;
  overflow-y: auto;
  padding: 0 !important;
}

/* Settings Slide pan */

.slide-pane__overlay.menu-nav-settings {
  z-index: 1001;
  top: 160px;
  top: var(--NavLogo);
  height: calc(100% - (160px + 160px) - 34px);
  height: calc(100% - (var(--NavLogo) + var(--NavUser)) - 34px);
}
.slide-pane__overlay.menu-nav-settings.ReactModal__Overlay--after-open {
  background-color: rgb(0 0 0 / 0%);
}
.slide-pane.slide-pane__mobile.extra-level-menu {
  background: #252527;
  box-shadow: none;
}
.slide-pane__mobile.extra-level-menu .slide-pane__content {
  background: #252527;
  height: calc(100% - 0px);
  bottom: 0;
  position: absolute;
  width: 100%;
}
.ReactModal__Content--after-open.slide-pane.slide-pane__mobile {
  border-right: 1px solid #252527;
}

/* ===
Tasks Slide pan ===
User Profile Side Pan === */
.slide-pane__overlay.task-panel-overlay,
.slide-pane__overlay.user-profile-pane {
  z-index: 999;
}

@media (max-width: 767px) {
  .slide-pane__overlay.mobile-vertical-nav {
    z-index: 1001;
  }
  /* Settings Slide pan */
  .slide-pane__overlay.menu-nav-settings {
    z-index: 1001;
    top: 130px;
    height: calc(100% - 188px);
  }
  .slide-pane.slide-pane__mobile.extra-level-menu {
    height: calc(100% - 60px);
    box-shadow: none;
    background: #252527;
  }
  div#navbar-user-mobile {
    height: 132px;
    overflow: hidden;
  }
  .navbar-links {
    height: 100%;
  }
}

/* ===MENU VERTICAL NAVBAR MOBILE=== */
.background-user-image {
  background: rgba(0, 0, 0, 0.3);
  background: var(--opacity-b-03);
  height: 180px;
}
.owner-user-image {
  height: 25px;
}
.owner-user-data {
  height: 60px;
  margin-bottom: 0.5em;
}
#navbar-user-mobile span#owner-user-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 1.25em;
}
#navbar-user-mobile span.owner-user-label {
  color: #949496;
  color: var(--LightGrey);
  line-height: 1.5em;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
#navbar-user-mobile .btn-large-icon {
  width: 70px;
  height: 70px;
}
.navbar-user-btn {
  position: absolute;
  top: -48px;
  left: calc(50% - 30px);
}
.navbar-user:before {
  content: "";
  background: #252527;
  width: 100%;
  height: 49px;
  position: absolute;
  top: -49px;
  left: 0;
  z-index: 0;
}
.nav-item-like {
  padding-left: 0.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #202023;
  font-size: 0.875em;
  border-left: 8px solid #28282c;
  height: 50px;
  align-items: center;
}
.nav-item-like:hover {
  color: rgba(255, 255, 255, 0.7);
  color: var(--opacity-w-07);
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
  border-left: 8px solid #0062ff;
  border-left: 8px solid var(--Blue);
}

@media (min-width: 992px) {
  .navbar-expand .nav-link {
    padding-right: 0.5rem;
    padding-left: 1.5rem;
    min-height: 60px;
    display: flex;
    align-items: center;
  }

  .nav-item-like {
    min-height: 60px;
  }

  .navbar a.selectedNav.nav-link,
  .navbar-dark a.selectedNav.nav-link {
    border-left: 8px solid #0062ff;
    border-left: 8px solid var(--Blue);
  }
  .navbar-expand .nav-link {
    border-left: 8px solid #28282c;
  }
}

@media (max-width: 991px) {
  .navbar-expand .nav-link,
  .nav-item-like {
    padding-right: 1rem;
    padding-left: 0.5rem;
  }
}

.nav-link span {
  display: flex;
  align-self: center;
  font-weight: 700;
  font-weight: var(--Bold);
}
.info-nav-item {
  color: rgba(255, 255, 255, 0.5) !important;
  color: var(--opacity-w-05) !important;
  font-weight: 500 !important;
}
a.info-nav-item span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.info-nav-item.nav-link:hover {
  color: #fff !important;
}
.navbar-dark .nav-link:hover {
  color: #ffffff;
  color: var(--White);
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
  border-left: 8px solid #0062ff;
  border-left: 8px solid var(--Blue);
}
.btn-large-icon.individual-member-btn-task {
  height: 52px;
  width: 52px;
}
.btn-large-icon.individual-member-btn-task {
  height: 32px;
  width: 32px;
}
span.owner-user-name {
  font-weight: 700;
  font-weight: var(--Bold);
  line-height: 0.9em;
  padding-bottom: 0.3em;
}
span.owner-user-label {
  color: #949496;
  color: var(--LightGrey);
  line-height: 1.5em;
  font-size: 0.9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

/* ==============================================
TOGGLE
=============================================== */

.toggle-switch {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-size: 0.875em;
  transition: 0.15s ease-in 0s;
  padding: 0.108em;
}
.toggle-switch-label {
  cursor: pointer;
}
.toggle-switch-inner-bgcolor {
  width: 200%;
  margin-left: -100%;
  transition: 0.15s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 100%;
  padding: 0;
  font-weight: bold;
  box-sizing: border-box;
  position: relative;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  text-transform: uppercase;
  padding-left: 0.75em;
  position: absolute;
  color: rgba(0, 0, 0, 0.9);
  color: var(--opacity-b-09);
  opacity: 0;
  transition: 0.15s ease-in 0s;
}
.toggle-switch-inner-bgcolor {
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
}
.toggle-switch-disabled,
.toggle-switch-disabled:before {
  background-color: rgba(255, 255, 255, 0.3);
  background-color: var(--opacity-w-03);
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 0.75em;
  color: rgba(0, 0, 0, 0.9);
  color: var(--opacity-b-09);
  text-align: right;
  width: 100%;
  opacity: 1;
  transition: 0.15s ease-in 0s;
}
.toggle-switch-switch {
  width: 20px;
  height: 20px;
  margin: 2px;
  top: 0;
  bottom: 0;
  right: calc(100% - 24px);
  transition: all 0.15s ease-in 0s;
}
.toggle-switch-checkbox:checked
  + .toggle-switch-label
  .toggle-switch-inner-bgcolor {
  margin-left: 0;
  right: 0;
  top: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0;
}
.toggle-switch-checkbox:checked
  + .toggle-switch-label
  .toggle-switch-inner:after {
  opacity: 0;
}
.toggle-switch-checkbox:checked
  + .toggle-switch-label
  .toggle-switch-inner:before {
  opacity: 1;
}

/* ==============================================
TOGGLE WITH ICON
=============================================== */

.switcher {
  width: 230px;
  height: 24px;
}
.switcher input {
  -webkit-appearance: none;
  appearance: none;
  position: relative;
  height: 24px;
  border-radius: 24px;
  cursor: pointer;
}
.switcher input:before,
.switcher input:after {
  z-index: 2;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  color: #fff;
}
.switcher input:before {
  left: 36px;
  font-size: 12px;
}
.switcher input:after {
  right: 28px;
  font-size: 12px;
}

.switcher label {
  z-index: 0;
  position: absolute;
  border-radius: 20px;
  margin: 0;
  pointer-events: none;
}
.switcher-label {
  position: absolute;
  top: 0;
  pointer-events: none;
  font-size: 12px;
}
.switcher-label.switcher-label01 {
  left: 0;
  width: 50%;
  height: 100%;
}

.switcher-label.switcher-label02 {
  right: 0;
  width: 50%;
  height: 100%;
}
.switcher input {
  transition: 0.25s -0.1s;
}
.switcher input:checked {
  background-color: #000;
}
.switcher input:checked:before {
  color: #000;
  transition: color 0.5s 0.2s;
}
.switcher input:checked:after {
  color: #ccc;
  transition: color 0.5s;
}

.switcher input:checked + label {
  left: 0;
  top: 0;
  right: 50%;
  width: 50%;
  height: 24px;
  background: rgb(255 255 255 / 70%);
  color: rgba(0, 0, 0, 0.9);
  color: var(--opacity-b-09);
  transition: left 0.5s, right 0.4s 0.2s;
}

.switcher input:not(:checked) {
  background: black;
  transition: background 0.5s -0.1s;
}

.switcher input:not(:checked):before {
  color: #ccc;
  transition: color 0.5s;
}
.switcher input:not(:checked):after {
  transition: color 0.5s 0.2s;
}

.switcher input:not(:checked) + label {
  width: 50%;
  right: 0;
  left: 115px;
  top: 0;
  height: 24px;
  background: rgba(255, 255, 255, 0.5);
  background: var(--opacity-w-05);
  transition: left 0.4s 0.2s, right 0.5s, background 0.35s -0.1s;
}

/* ==============================================
PUBLIC SHARE PAGE & SHARE COMPONENT
PUBLIC PRICING PAGE & PRICING COMPONENT
=============================================== */
.public-share-page,
.public-pricing-page {
  height: 100vh;
  overflow: hidden;
  background-color: #141414;
  background-image: url(/images/radial.svg);
  background-size: cover;
  background-position: 50%;
}

.public-share-page h6,
.public-pricing-page h6 {
  font-size: 9vw;
}

.public-share-box {
  border-radius: 1em;
  width: 380px;
  max-height: calc(100vh - 6rem);
  position: relative;
}

.public-share-box-scrollable {
  overflow: auto;
  height: 100%;
}
.public-share-box .btn-large-icon {
  width: 48px;
  height: 48px;
  padding: 0;
}
.pricing-blue-box {
  border-radius: 1rem;
}

/* Allows dropdown icon visibility */
.css-1dc73bp-indicatorContainer svg {
  display: block !important;
}

@media (max-width: 991px) {
  .public-share-page,
  .public-pricing-page {
    min-height: 100vh;
    height: auto;
    overflow: scroll;
  }
  .public-share-box,
  .pricing-blue-box {
    max-height: 100%;
    height: auto;
    padding: 1em 1.5em;
  }
  .public-share-page h6,
  .public-pricing-page h6 {
    font-size: 15.5vw;
  }
}

@media (max-width: 767px) {
  .public-share-box-scrollable {
    overflow: auto;
    height: auto;
  }

  .public-share-page h6,
  .public-pricing-page h6 {
    font-size: 17.5vw;
  }
}
@media (max-width: 460px) {
  .public-share-box,
  .pricing-blue-box {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
@media (max-height: 600px) {
  .public-share-page {
    height: auto;
  }
}
@media (max-height: 900px) {
  .public-pricing-page {
    height: auto;
    min-height: 100vh;
  }
}

/* ========================
  PAYMENT PAGE
=========================== */

.gradientList {
  position: absolute;
  background: linear-gradient(
  0deg,
  rgb(40 200 130) 12%,
  rgba(0, 0, 250, 0) 100%
);
  height: 40px;
  width: calc(100% - 3rem);
  bottom: 0.5rem;
}

.pricing-page-content-layout.row {
  max-width: 1200px;
  margin: 0 auto;
}
.nav-link.price-column-link {
  display: inline-block;
  padding: 0.5rem 1.5rem;
}
.delic-payment-container {
  width: 345px;
  box-shadow: 0 0.25em 2.5em -0.25em rgba(0, 0, 0, 0.9);
  box-shadow: 0 0.25em 2.5em -0.25em var(--opacity-b-09);
  border-radius: 1.25em;
  overflow: hidden;
  position: relative;
}
.delic-payment-body {
  background: #28272c;
  padding: 1.25em;
}
.delic-payment-arrow {
  position: absolute;
  left: 1em;
  top: 2.5em;
}
.payment-head {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #212123;
  height: 114px;
}
.StripeElement {
  display: block;
  margin: 0.25em 0 0.5em 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: rgb(255 255 255 / 5%);
  border-bottom: 3px solid #28272c;
}
.delic-payment-expiry,
.delic-payment-cvc {
  width: 100px;
}
.CardBrandIcon-container {
  right: 0;
}
.StripeElement--focus {
  border-bottom: 3px solid #0062ff;
  border-bottom: 3px solid var(--Blue);
}
.StripeElement--focus .ElementsApp .Icon-fill {
  fill: #0062ff;
  fill: var(--Blue);
}
ul.delic-payment-steps {
  list-style: none;
}
.delic-payment-steps li {
  width: 40px;
  justify-content: center;
}
li.delic-payment-line {
  position: relative;
}
li.delic-payment-line:after {
  content: "";
  height: 3px;
  width: 120px;
  background: rgba(255, 255, 255, 0.1);
  background: var(--opacity-w-01);
  position: absolute;
  top: 31px;
  right: -116px;
}
li.delic-payment-line.green-payment-line:after {
  background: #28c882;
  background: var(--Light-Green);
}
li.delic-payment-line.red-payment-line:after {
  background: #ff3200;
  background: var(--Red);
}
.StripeElement.StripeElement--invalid {
  border-bottom: 3px solid #ff3200;
  border-bottom: 3px solid var(--Red);
}

/* ========================
  HEADER || LEFT MENU
=========================== */

.navbar-logo {
  background: rgba(0, 0, 0, 0.1);
  background: var(--opacity-b-01);
  width: 100%;
  height: 160px;
  height: var(--NavLogo);
}
.navbar-dark .nav-link {
  color: #ffffff;
  color: var(--White);
}
.navbar {
  padding: 0;
  height: 100%;
  height: calc(100vh - 64px);
}
.navbar-expand .nav-link {
  border-bottom: 1px solid #202023;
  display: flex;
}
.navbar-expand.navbar-dark {
  background-color: #252527;
}
.nav-menu-icon {
  min-width: 30px;
  height: 30px;
  position: relative;
  overflow: hidden;
  align-items: center;
}
.nestedNav.nav-link .nav-menu-icon {
  color: #1964ff;
  color: var(--Light-blue);
}
#App-header.mobile-menu-slide-in {
  left: 0;
  box-shadow: 6px 0 20px 5px rgba(0,0,0,0.27059);
}
.menuSlide-btn.mobile-menu-slide-in {
  -webkit-transform: translate(calc(80% - 1px), 0);
          transform: translate(calc(80% - 1px), 0);
  -webkit-transform: translate(calc(var(--menuPanel) - 1px), 0);
          transform: translate(calc(var(--menuPanel) - 1px), 0);
  border-radius: 25px;
  background: #949496;
  background: var(--LightGrey);
  color: #2f2f2f;
  color: var(--DarkerGrey);
}
.nav-link {
  padding-right: 0.5rem;
  padding-left: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
#side-panel-menu img {
  width: 200px;
}
.navbar-user {
  position: absolute;
  bottom: 0;
  height: 160px;
  height: var(--NavUser);
  background: #333335;
  width: 100%;
  padding: 15px;
  justify-content: space-around;
}
a.mobile-nav-item.nav-link {
  color: rgba(255, 255, 255, 0.3);
  color: var(--opacity-w-03);
  font-size: 1em !important;
}
.mob-w-20 {
  width: 20%;
}
.mob-w-33 {
  width: 33.3333%;
}
.mob-w-50 {
  width: 50%;
}
.mob-w-66 {
  width: 66.6666%;
}
.mobile-nav-item.nav-link span {
  font-weight: 400 !important;
}
.navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-expand .nav-link {
  border-left: 8px solid #28282c;
  font-size: 0.875em;
}
.mobile-nav-item.nav-link.selectedNav {
  color: rgba(255, 255, 255, 0.9);
  color: var(--opacity-w-09);
}
.navbar a.selectedNav.nav-link {
  border-left: 0.5rem solid #0062ff;
  border-left: 0.5rem solid var(--Blue);
  background: rgba(255, 255, 255, 0.025);
  background: var(--opacity-w-0025);
  color: rgba(255, 255, 255, 0.7);
  color: var(--opacity-w-07);
}
a.nestedNav.nav-link.selectedNav .nav-menu-icon {
  color: #1964ff;
  color: var(--Light-blue);
}
.navbar-dark .nestedNav.nav-link:hover {
  color: rgba(255, 255, 255, 0.7);
  color: var(--opacity-w-07);
  background: rgba(255, 255, 255, 0.05);
  background: var(--opacity-w-005);
  border-left: 8px solid #0062ff;
  border-left: 8px solid var(--Blue);
}
a.selectedNav.nestedNav.nav-link:focus,
a.nestedNav.nav-link:hover {
  background: rgba(0, 0, 0, 0.3);
  background: var(--opacity-b-03);
}
a.selectedNav.nav-link .nav-menu-icon {
  color: #1964ff;
  color: var(--Light-blue);
}

@media (min-width: 768px) {
  .navbar {
    height: 100% !important;
  }
}

@media (max-width: 540px) {
  #navbar-user-mobile span#owner-user-name {
    font-size: 1em;
  }
  #navbar-user-mobile span.owner-user-label {
    font-size: 0.8em;
  }
  #navbar-user-mobile .btn-large-icon {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 720px) and (orientation: portrait) {
  .navbar-user-btn {
    top: -30px;
  }
}
@media (max-height: 720px) {
  .navbar-logo {
    height: 160px;
    height: var(--NavLogo);
  }
}
@media (max-height: 920px) {
  .navbar-expand .nav-link {
    min-height: 50px;
  }
  .nav-item-like {
    min-height: 50px;
  }
}

/* =======================================
  ==========================================
  3 COLUMNS LAYOUT
  ==========================================
  ======================================= */

:root {
  --menuPanel: 80%;
  --Right-Side-Panel: 100%;
  --NavLogo: 160px;
  --PaddingXMidPanel: 1em;
  --NavUser: 160px;
  --SidePanelsColorBg: #28282c;
}

header#App-header {
  background: #28282c;
  background: var(--SidePanelsColorBg);
  position: unset;
  left: calc(-1 * 80%);
  left: calc(-1 * var(--menuPanel));
  height: 100%;
  width: 100%;
  transition: 0.3s ease-in-out;
  z-index: 990;
}
#fullwidth-content {
  padding: 0 0.25em 4em;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}
#middle-panel-content {
  width: 100%;
  margin-left: 0;
  transition: 0.15s ease-in-out;
}

#middle-panel-container {
  width: 100%;
  padding: 0.5em 1em 6em;
  position: relative;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
}
#side-panel-tasks,
#side-panel-user-profile {
  background: rgb(37 37 39);
  max-width: 100%;
  max-width: var(--Right-Side-Panel);
  width: 100%;
  width: var(--Right-Side-Panel);
  box-shadow: -6px 0 20px 5px rgba(0,0,0,0.01961);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
}

.tasks-panel-main {
  padding: 0 1em;
  position: relative;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 1em;
}

#fullwidth-panel-content {
  padding: 0 0.25em 4em;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
}

.bottom-nav-mobile {
  position: fixed;
  height: 58px !important;
  width: 100%;
  background: #303030;
  bottom: 0;
  z-index: 1002;
  box-shadow: 0 -10px 18px -10px black;
  align-items: center;
  transition: 0.15s ease-in-out;
}

@media (min-width: 576px) {
  :root {
    --Right-Side-Panel: 520px;
  }
}

@media (min-width: 768px) {
  :root {
    --menuPanel: 200px;
  }
  header#App-header {
    background: #28282c;
    background: var(--SidePanelsColorBg);
    position: fixed;
    height: 100%;
    left: 0;
    width: 80%;
    width: var(--menuPanel);
    transition: 0.15s ease-in-out;
    z-index: 990;
  }

  #middle-panel-container {
    max-width: 700px;
  }

  #middle-panel-content {
    width: calc(100% - 80%);
    width: calc(100% - var(--menuPanel));
    margin-left: 80%;
    margin-left: var(--menuPanel);
    padding: 0 1em;
    padding: 0 var(--PaddingXMidPanel);
  }

  #side-panel-tasks,
  #side-panel-user-profile,
  .slide-pane__overlay.project-members-manager-overlay {
    max-width: 520px;
    width: 100%;
  }
  header#App-header {
    left: 0;
    width: 80%;
    width: var(--menuPanel);
    box-shadow: 6px 0 20px 5px rgba(0,0,0,0.01961);
  }
  #fullwidth-panel-content {
    width: calc(100% - 80%);
    width: calc(100% - var(--menuPanel));
    margin-left: 80%;
    margin-left: var(--menuPanel);
    padding: 0 1em;
  }
  .tasks-panel-main {
    padding: 0 10px 0px 25px;
  }
  .bottom-nav-mobile {
    display: none;
  }
}

@media (min-width: 992px) {
  :root {
    --menuPanel: 265px;
  }
  #fullwidth-panel-content {
    padding: 0 3em;
  }
}

@media (min-width: 1201px) {
  :root {
    --PaddingXMidPanel: 20px;
    --Right-Side-Panel: 30vw;
  }
  #middle-panel-content {
    width: calc(100vw - (80% + 100%));
    width: calc(100vw - (var(--menuPanel) + var(--Right-Side-Panel)));
    position: relative;
    box-sizing: border-box;
    transition: 0.4s ease-in-out;
    padding: 0 1em;
    padding: 0 var(--PaddingXMidPanel);
  }
  #side-panel-tasks,
  #side-panel-user-profile,
  .slide-pane__overlay.project-members-manager-overlay {
    max-width: 100%;
    max-width: var(--Right-Side-Panel);
  }
}

@media (min-width: 1400px) {
  :root {
    --PaddingXMidPanel: 40px;
  }
}

/* ========================
  FULL BODY DROPZONE
=========================== */
.full-body-dropzone-text-area {
  z-index: 1003;
  pointer-events: none;
}

.SimpleReference-module_simpleReferenceWrap__iTAnS {
    border-radius: 10px;
    overflow: hidden;
    width: 100% !important;
    max-height: 300px;
}

@media (min-width: 320px) {
    .SimpleReference-module_simpleReferenceWrap__iTAnS {
        max-height: 200px;
        background: #19191c;
    }
}

@media (min-width: 768px) {
    .SimpleReference-module_simpleReferenceWrap__iTAnS {
        max-height: 300px;
        background: #19191c;
    }
}

.SimpleReference-module_simpleIdeaReferenceWrap__bvG5P {
    border-radius: 10px;
    width: 100%!important;
    position: relative;
    overflow: hidden;
}

.SimpleReference-module_public-previous-live-wrap__tDrU3 .SimpleReference-module_simpleIdeaReferenceWrap__bvG5P {
    width: 100% !important;
    height: 100% !important;
}

@media (min-width: 320px) {
    .SimpleReference-module_simpleIdeaReferenceWrap__bvG5P {
        max-height: 200px;
        background: #19191c;
    }
}

@media (min-width: 768px) {
    .SimpleReference-module_simpleIdeaReferenceWrap__bvG5P {
        max-height: 360px;
        background: #19191c;
    }
}

@media (hover: hover) {
  .actionButton:hover, .actionButton:focus {
    background: var(--Blue);
    color: white;
  }
}

@media (max-width: 767px){
  .iconsContainer-dashboardWork{
    width: 100%;
  }
}

@media (min-width: 768px){
  .iconsContainer-dashboardWork{
      width: auto !important;
  }
}
.SimpleWorkMainActionContainer-module_simpleWorkThumbnail__adTfZ {
    padding: 1.25em 1em;
    min-width: 80px;
    min-height: 94px;
    height: 100%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    overflow: hidden;
    border-radius: .5rem;
}

@media (min-width: 576px){

    .SimpleWorkMainActionContainer-module_simpleWorkThumbnail__adTfZ {
        padding: 1.25em 1em;
        min-width: 94px;
    }
}
.SimpleWork-module_wrapperViewer__XRg1K {
  transition: .5s ease-in-out;
  overflow: hidden;
  background: var(--opacity-b-03);
  border-radius: 1em 1em 0 0;
}
.SimpleWork-module_wrapperViewer__XRg1K iframe {
  border: none !important;
}


.SimpleWork-module_open-viewer__YFOu- .SimpleWork-module_wrapperViewer__XRg1K {
  border-radius: 0 0 0 1em;
}

.SimpleWork-module_noPreviewThumb__yv-66 {
  min-width: 80px;
  min-height: 80px;
  background: var(--opacity-b-05);
  border-radius: .5rem;
}
.ImportFileItem-module_importFileItem__JWlj- {
  background: var(--opacity-b-03);
  margin: .15em 0 !important;
  border-radius: .25em !important;
}
.ImportFileItem-module_importFileItem__JWlj-:hover {
  background: var(--Blue);
  color: var(--White)
}
.SharedWorkMainActionContainer-module_sharedWorkThumbnail__L52zx {
    padding: 1.25em 1em;
    min-width: 80px;
    min-height: 94px;
    height: 100%;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    overflow: hidden;
    border-radius: .5rem;
}

@media (min-width: 576px){

    .SharedWorkMainActionContainer-module_sharedWorkThumbnail__L52zx {
        padding: 1.25em 1em;
        min-width: 94px;
    }
}
.SharedWork-module_wrapperViewer__h6FUO {
  transition: .5s ease-in-out;
  overflow: hidden;
  background: var(--opacity-b-03);
  border-radius: 1em 1em 0 0;
}
.SharedWork-module_wrapperViewer__h6FUO iframe {
  border: none !important;
}


.SharedWork-module_open-viewer__gIGQ0 .SharedWork-module_wrapperViewer__h6FUO {
  border-radius: 0 0 0 1em;
}

.SharedWork-module_noPreviewThumb__lEf36 {
  min-width: 80px;
  min-height: 80px;
  background: var(--opacity-b-05);
  border-radius: .5rem;
}

.DashboardWork-module_wrapperViewer__9ezOt {
  transition: .5s ease-in-out;
  overflow: hidden;
  background: var(--opacity-b-03);
  border-radius: 1em 1em 0 0;
}
.DashboardWork-module_wrapperViewer__9ezOt iframe {
  border: none !important;
}


.DashboardWork-module_open-viewer__F8UmR .DashboardWork-module_wrapperViewer__9ezOt {
  border-radius: 0 0 0 1em;
}

.DashboardWork-module_noPreviewThumb__hL5k- {
  min-width: 80px;
  min-height: 80px;
  background: var(--opacity-b-05);
  border-radius: .5rem;
}